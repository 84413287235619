/* Router */
import Home from 'content/pages/Home/Home';
import Login from 'content/pages/Login/Login';
import RegisterUser from 'content/pages/Register/Register';
import RecoveryPassword from 'content/pages/Recovery/RecoveryPassword';
import SummaryDiscount from 'content/pages/SummaryDiscounts/SummaryDiscount';
import DetailsDiscount from 'content/pages/DetailsDiscounts/DetailsDiscount';
import BulkLoad from 'content/pages/BulkLoad/BulkLoad';
import ListPayroll from 'content/pages/ListPayroll/ListPayroll';
import DetailsPayroll from 'content/pages/DetailsPayroll/DetailsPayroll';
import User from 'content/pages/User/User';
import ChangePassword from 'content/pages/ChangePassword/ChangePassword';
import ItemTypes from 'content/pages/ItemTypes/ItemTypes';

const Paths = {
  publics: [
    {
      path: '/login',
      component: Login,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
    {
      path: '/recovery/*',
      component: RecoveryPassword,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
    {
      path: '/register',
      component: RegisterUser,
      useLayout: false,
      Icon: null,
      isAuth: true,
    },
  ],
  shared: [],
  protecteds: [
    {
      path: '/',
      name: 'Inicio',
      component: Home,
      useLayout: true,
      Icon: null,
    },
    {
      path: '/list-payroll',
      name: 'Liquidaciones',
      component: ListPayroll,
      useLayout: true,
    },
    {
      path: '/details-payroll/:id',
      component: DetailsPayroll,
      useLayout: true,
    },
    {
      path: '/summary-discount',
      name: 'Reporte descuentos',
      component: SummaryDiscount,
      useLayout: true,
      Icon: null,
    },
    {
      path: '/details-discount/:id',
      component: DetailsDiscount,
      useLayout: true,
      Icon: null,
    },
    {
      path: '/bulk-load',
      name: 'Proceso liquidaciones',
      component: BulkLoad,
      useLayout: true,
      Icon: null,
      rol: ['admin'],
    },
    {
      path: '/users',
      name: 'Usuarios',
      component: User,
      useLayout: true,
      Icon: null,
      rol: ['admin'],
    },
    {
      path: '/change-password',
      component: ChangePassword,
      useLayout: true,
      Icon: null,
    },
    {
      path: '/item-types',
      name: 'Tipos de descuentos',
      component: ItemTypes,
      useLayout: true,
      Icon: null,
      rol: ['admin'],
    },
  ],
  independients: [],
};

export default Paths;
