import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import {
  fetchPayroll,
  fetchPayrollCards,
  fetchPayrollFail,
  fetchPayrollSuccess,
  fetchPayrollCardsSuccess,
  fetchPayrollCardsFail,
} from './list-payroll.actions';

function* getEmployee() {
  yield takeLatest(fetchPayroll, function* getEmployeeToApi(action) {
    const { pageSize, actualPage, searchValue, dateValue, processValue, statusValue } =
      action.payload;
    const response = yield apiRequest(
      `api/v1/employee?start_date=${dateValue}&take=${pageSize}&page=${actualPage}&search=${searchValue}&isReissued=${processValue}&isOpen=${statusValue}`,
      {
        method: 'GET',
      },
    );
    if (response) {
      yield put(
        fetchPayrollSuccess({
          objects: response.objects,
          totalPages: response.pagination.totalPages,
          actualPage: response.pagination.currentPage,
        }),
      );
    } else {
      yield put(fetchPayrollFail());
    }
  });
  yield takeLatest(fetchPayrollCards, function* getEmployeeCardsToApi(action) {
    const { dateValue, processValue, statusValue } = action.payload;
    const response = yield apiRequest(
      `api/v1/employee/cards?start_date=${dateValue}&isReissued=${processValue}&isOpen=${statusValue}`,
      {
        method: 'GET',
      },
    );
    if (response) {
      yield put(
        fetchPayrollCardsSuccess({
          objects: response.object,
        }),
      );
    } else {
      yield put(fetchPayrollCardsFail());
    }
  });
}
export default function* EmployeeSaga() {
  yield spawn(getEmployee);
}
