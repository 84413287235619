import { createReducer } from '@reduxjs/toolkit';

import {
  setControl,
  setControls,
  resetControls,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
} from './changePassword.actions';

const initialState = {
  controls: {
    password: '',
    rePassword: '',
  },
  isLoading: false,
};

const ChangePasswordReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setControl, (state, action) => {
      state.controls = { ...state.controls, ...action.payload };
    })
    .addCase(setControls, (state, action) => {
      state.controls = { ...state.controls, ...action.payload };
    })
    .addCase(resetControls, (state) => {
      state.controls = { ...initialState.controls };
    })
    .addCase(updatePassword, (state) => {
      state.isLoading = true;
    })
    .addCase(updatePasswordSuccess, (state) => {
      state.isLoading = false;
      state.controls = { ...initialState.controls };
    })
    .addCase(updatePasswordFail, (state) => {
      state.isLoading = false;
    });
});

export default ChangePasswordReducer;
