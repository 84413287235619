const BASE = '[CHANGE_PASSWORD]';

const ChangePasswordTypes = {
  SET_CONTROLS: `${BASE} SET_CONTROLS`,
  SET_CONTROL: `${BASE} SET_CONTROL`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
  UPDATE_PASSWORD: `${BASE} UPDATE_PASSWORD`,
  UPDATE_PASSWORD_SUCCESS: `${BASE} UPDATE_PASSWORD_SUCCESS`,
  UPDATE_PASSWORD_FAIL: `${BASE} UPDATE_PASSWORD_FAIL`,
};

export default ChangePasswordTypes;
