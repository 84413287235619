import { Box } from '@mui/material';
import SearchInput from 'content/features/other/components/SearchInput';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeSearchValue } from '../itemType.actions';

function ItemTypeSearch() {
  const dispatch = useDispatch();
  const handleChange = (value) => {
    dispatch(changeSearchValue({ searchValue: value }));
  };
  useEffect(
    () => () => {
      handleChange('');
    },
    [],
  );
  return (
    <Box bgcolor="#F0F0F0" p={3} borderRadius={2} alignItems="center" spacing={2}>
      <SearchInput placeholder="Buscar por nombre o código" onChange={handleChange} />
    </Box>
  );
}

export default ItemTypeSearch;
