import React, { useEffect, useState } from 'react';
import { io } from 'socket.io-client';
import moment from 'moment';
import 'moment/locale/es';

/* Material UI */

/* Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import CustomBox from 'content/features/bull-load/components/CustomBox';
import {
  loadDataBase,
  insertedRecords,
  loadingProcess,
  lastPeriod,
  setLoadDate,
  getClosedPayrollLoad,
} from 'content/features/bull-load/bulkLoad.actions';
import {
  loadingLoadSelector,
  loadingProcessSelector,
  insertedRecordsSelector,
  monthSelector,
  yearSelector,
  dateSelector,
  finishSelector,
  loadIsDisabledSelector,
} from 'content/features/bull-load/bulkLoad.selector';
import environments from 'config/environments';
import { formatNumber } from 'utils/functions';
import { Stack, Switch, ThemeProvider, Typography, createTheme } from '@mui/material';

function ExtractionBox() {
  moment.locale('es');
  const socket = io(environments.WS_ENDPOINT, { transports: ['websocket'] });
  const dispatch = useDispatch();
  const loadingLoad = useSelector(loadingLoadSelector);
  const progress = useSelector(loadingProcessSelector);
  const record = useSelector(insertedRecordsSelector);
  const month = useSelector(monthSelector);
  const year = useSelector(yearSelector);
  const date = useSelector(dateSelector);
  const finish = useSelector(finishSelector);
  const isDisabled = useSelector(loadIsDisabledSelector);
  const [checked, setChecked] = useState(false);

  const data = {
    title: 'Extracción desde BUK y cálculo de insolutos',
    items: [
      {
        id: 'period',
        title: 'Período',
        data: `${moment()
          .month(month - 1)
          .format('MMMM')} ${year}`,
      },

      {
        id: 'last',
        title: 'Última actualización',
        data: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}`,
      },

      {
        id: 'totalRecords',
        title: 'Cantidad de registros',
        data: `${formatNumber(record)}`,
      },

      {
        id: 'state',
        title: 'Estado',
        data: progress,
      },
    ],
    button: {
      text: `Extraer ${checked ? 'reliquidacion' : 'liquidacion'}`,
      loading: loadingLoad,
      isDisabled,
    },
  };

  useEffect(() => {
    dispatch(lastPeriod());
    // TODO: quitar fecha hardcode
    dispatch(
      getClosedPayrollLoad({
        date: '2023-09-01',
        isReissued: checked ? '1' : '0',
        button: 'extraction',
      }),
    );
  }, [finish, checked]);

  useEffect(() => {
    if (socket) {
      socket.on('[UPLOAD_DATA_PROGRESS]', (message) => {
        dispatch(insertedRecords({ record: message.objects.record }));
        dispatch(loadingProcess({ progress: message.objects.progress }));
      });

      return () => {
        socket.disconnect();
      };
    }

    return () => {};
  }, [socket]);

  useEffect(() => {
    if (loadingLoad) {
      const interval = setInterval(() => {
        dispatch(setLoadDate({ date: moment() }));
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [loadingLoad]);

  const handleBulkLoad = () => {
    dispatch(loadDataBase({ isReissued: checked ? '1' : '0' }));
  };
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const theme = createTheme({
    components: {
      MuiSwitch: {
        styleOverrides: {
          switchBase: {
            color: '#0176DE',
          },
          colorPrimary: {
            '&.Mui-checked': {
              color: '#0176DE',
            },
          },
          track: {
            opacity: 0.5,
            backgroundColor: '#0176DE',
            '.Mui-checked.Mui-checked + &': {
              opacity: 0.5,
              backgroundColor: '#0176DE',
            },
          },
        },
      },
    },
  });

  return (
    <CustomBox handleClick={handleBulkLoad} data={data}>
      <Stack direction="row" spacing={1} alignItems="center" pr={2}>
        <Typography>liquidacion</Typography>
        <ThemeProvider theme={theme}>
          <Switch checked={checked} onChange={handleChange} />
        </ThemeProvider>
        <Typography>reliquidacion</Typography>
      </Stack>
    </CustomBox>
  );
}

export default ExtractionBox;
