import React from 'react';

import { InputBase, IconButton, Box, Grid } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

function SearchInput({ placeholder, onChange }) {
  return (
    <Box bgcolor="#FFF" border={1} borderColor="#c1c1c1" borderRadius={2} pl={2} pr={3}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item md={11}>
          <InputBase
            sx={{ width: '100%' }}
            placeholder={placeholder}
            onChange={(event) => {
              onChange(event.target.value);
            }}
          />
        </Grid>
        <Grid item md={1} textAlign="right">
          <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
            <SearchIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SearchInput;
