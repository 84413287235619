import React from 'react';

import { Divider } from '@mui/material';
import { useTheme } from '@mui/material/styles';

function CustomDivider({ color, type }) {
  const theme = useTheme();
  let widthSize;
  let heightSize;
  if (type === 'card') {
    widthSize = '30px';
    heightSize = '3px';
  } else {
    widthSize = '60px';
    heightSize = '5px';
  }

  const customDividerStyles = {
    backgroundColor: theme.palette[color].main,
    height: heightSize,
    margin: '3px 0',
    width: widthSize,
    border: '0',
  };

  return <Divider style={customDividerStyles} />;
}

export default CustomDivider;
