import { Button, Grid } from '@mui/material';
import PageTitle from 'content/features/other/components/PageTitle';
import React from 'react';

function ItemTypeTitle({ setModal }) {
  const handleClick = () => {
    setModal({
      isOpen: true,
      isNew: true,
    });
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PageTitle title="Lista tipos de descuentos" />
      </Grid>
      <Grid item>
        <Button variant="contained" size="large" onClick={handleClick}>
          Crear descuento
        </Button>
      </Grid>
    </Grid>
  );
}

export default ItemTypeTitle;
