import { createAction } from '@reduxjs/toolkit';
import ListPayrollTypes from './list-payroll.types';

export const fetchPayroll = createAction(ListPayrollTypes.FETCH_PAYROLL);

export const fetchPayrollSuccess = createAction(
  ListPayrollTypes.FETCH_PAYROLL_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
      totalPages: payload.totalPages,
      currentPage: payload.currentPage,
    },
  }),
);

export const fetchPayrollFail = createAction(ListPayrollTypes.FETCH_PAYROLL_FAIL);

export const fetchPayrollCards = createAction(ListPayrollTypes.FETCH_PAYROLL_CARDS);

export const fetchPayrollCardsSuccess = createAction(
  ListPayrollTypes.FETCH_PAYROLL_CARDS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objectsCards: payload.objectsCards,
    },
  }),
);

export const fetchPayrollCardsFail = createAction(ListPayrollTypes.FETCH_PAYROLL_CARDS_FAIL);

export const changePagination = createAction(ListPayrollTypes.CHANGE_PAGINATION, (payload) => ({
  payload: {
    ...payload,
    currentPage: payload.currentPage,
  },
}));

export const changeSearchValue = createAction(ListPayrollTypes.CHANGE_SEARCH_VALUE, (payload) => ({
  payload: {
    ...payload,
    searchValue: payload.searchValue,
  },
}));

export const changeDateValue = createAction(ListPayrollTypes.CHANGE_DATE_VALUE, (payload) => ({
  payload: {
    ...payload,
    dateValue: payload.dateValue,
  },
}));
export const changeProcessValue = createAction(
  ListPayrollTypes.CHANGE_PROCESS_VALUE,
  (payload) => ({
    payload: {
      ...payload,
      processValue: payload.processValue,
    },
  }),
);
export const changeStatusValue = createAction(ListPayrollTypes.CHANGE_STATUS_VALUE, (payload) => ({
  payload: {
    ...payload,
    statusValue: payload.statusValue,
  },
}));

export const setResetState = createAction(ListPayrollTypes.RESET_STATE);

export const changeLocationKey = createAction(ListPayrollTypes.CHANGE_LOCATION_KEY, (payload) => ({
  payload: {
    locationKey: payload.locationKey,
  },
}));

const ListPayrollActions = {
  fetchPayroll,
  fetchPayrollSuccess,
  fetchPayrollFail,
  changePagination,
  setResetState,
};

export default ListPayrollActions;
