import React from 'react';

/* Material UI */
import { Box, Grid, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

function CustomBox(props) {
  const { handleClick, data, children } = props;

  return (
    <Box bgcolor="#F0F0F0" paddingX={10} paddingY={3} marginTop={5}>
      <Typography marginBottom={1} fontSize={20}>
        {data.title}
      </Typography>
      <Grid
        container
        spacing={2}
        justifyContent="space-around"
        bgcolor="#FFF"
        border={1}
        borderColor="#FFF"
        borderRadius={2}
        padding={2}
        marginTop={0}
        marginLeft={0}
        marginBottom={2}
        boxShadow={3}>
        {data.items[3].data === 'no data' ? (
          'No hay último período'
        ) : (
          <>
            {data.items.map((item) => (
              <Grid key={item.id}>
                <Typography fontWeight="bold">{item.title}</Typography>
                <Typography>{item.data}</Typography>
              </Grid>
            ))}
          </>
        )}
      </Grid>
      <Box display="flex" justifyContent="flex-end" alignItems="center">
        {children}
        <LoadingButton
          disabled={data.button.isDisabled}
          loading={data.button.loading}
          variant="contained"
          color="secondary"
          size="large"
          onClick={() => handleClick()}>
          {data.button.text}
        </LoadingButton>
      </Box>
    </Box>
  );
}

export default CustomBox;
