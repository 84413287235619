const BASE = '[User]';

const UserTypes = {
  FETCH_USERS: `${BASE} FETCH_USERS`,
  FETCH_USERS_SUCCESS: `${BASE} FETCH_USERS_SUCCESS`,
  FETCH_USERS_FAIL: `${BASE} FETCH_USERS_FAIL`,
  CHANGE_SEARCH_VALUE: `${BASE} CHANGE_SEARCH_VALUE`,
  CHANGE_IS_NEW: `${BASE} CHANGE_IS_NEW`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  SEND_USER: `${BASE} SEND_USER`,
  SEND_USER_SUCCESS: `${BASE} SEND_USER_SUCCESS`,
  SEND_USER_FAIL: `${BASE} SEND_USER_FAIL`,
  UPDATE_USER: `${BASE} UPDATE_USER`,
  UPDATE_USER_SUCCESS: `${BASE} UPDATE_USER_SUCCESS`,
  UPDATE_USER_FAIL: `${BASE} UPDATE_USER_FAIL`,
  SET_CONTROLS: `${BASE} SET_CONTROLS`,
  SET_CONTROL: `${BASE} SET_CONTROL`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
};

export default UserTypes;
