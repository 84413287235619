import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchItemType, fetchDiscountType } from './itemType.actions';
import {
  isLoadingCreateSelector,
  isLoadingUpdateSelector,
  paginationSelector,
  searchValueSelector,
} from './itemType.selector';

function useItemType() {
  const dispatch = useDispatch();
  const searchValue = useSelector(searchValueSelector);
  const paginationOptions = useSelector(paginationSelector);
  const isLoadingCreate = useSelector(isLoadingCreateSelector);
  const isLoadingUpdate = useSelector(isLoadingUpdateSelector);
  useEffect(() => {
    dispatch(
      fetchItemType({
        searchValue,
        pageSize: paginationOptions.pageSize,
        actualPage: paginationOptions.currentPage,
      }),
    );
  }, [searchValue, paginationOptions, isLoadingCreate, isLoadingUpdate]);

  useEffect(() => {
    dispatch(fetchDiscountType());
  }, []);
}

export default useItemType;
