import React, { useEffect } from 'react';
import { io } from 'socket.io-client';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import 'moment/locale/es';

/* Project */
import { formatNumber } from 'utils/functions';
import CustomBox from 'content/features/bull-load/components/CustomBox';
import {
  finishSendSelector,
  lastDateSendSelector,
  lastMonthSendSelector,
  lastYearSendSelector,
  loadingSendSelector,
  sendIsDisabledSelector,
  sendingProcessSelector,
  updatedRecordsSelector,
} from 'content/features/bull-load/bulkLoad.selector';
import environments from 'config/environments';
import {
  getClosedPayrollSend,
  lastUpdate,
  sendItems,
  sendingProcess,
  setSendDate,
  updatedRecords,
} from '../bulkLoad.actions';

function SendBox() {
  const socket = io(environments.WS_ENDPOINT, { transports: ['websocket'] });
  const dispatch = useDispatch();
  const loadingSend = useSelector(loadingSendSelector);
  const progress = useSelector(sendingProcessSelector);
  const record = useSelector(updatedRecordsSelector);
  const month = useSelector(lastMonthSendSelector);
  const year = useSelector(lastYearSendSelector);
  const date = useSelector(lastDateSendSelector);
  const finish = useSelector(finishSendSelector);
  const isDisabled = useSelector(sendIsDisabledSelector);

  const data = {
    title: 'Envío de insolutos',
    items: [
      {
        id: 'period',
        title: 'Período',
        data: `${moment()
          .month(month - 1)
          .format('MMMM')} ${year}`,
      },

      {
        id: 'last',
        title: 'Último envío de insolutos',
        data: `${moment(date).format('DD-MM-YYYY HH:mm:ss')}`,
      },

      {
        id: 'totalRecords',
        title: 'Cantidad de registros enviados',
        data: `${formatNumber(record)}`,
      },

      {
        id: 'state',
        title: 'Estado',
        data: progress,
      },
    ],
    button: { id: 'send', text: 'Enviar insolutos a Buk', loading: loadingSend, isDisabled },
  };

  useEffect(() => {
    dispatch(lastUpdate());
    dispatch(getClosedPayrollSend({ button: 'send' }));
  }, [finish]);

  useEffect(() => {
    if (socket) {
      socket.on('[SEND_DATA_PROGRESS]', (message) => {
        dispatch(updatedRecords({ record: message.objects.record }));
        dispatch(sendingProcess({ progress: message.objects.progress }));
      });

      return () => {
        socket.disconnect();
      };
    }

    return () => {};
  }, [socket]);

  useEffect(() => {
    if (loadingSend) {
      const interval = setInterval(() => {
        dispatch(setSendDate({ date: moment() }));
      }, 1000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [loadingSend]);

  const handleSendItem = () => {
    dispatch(sendItems({ date: '2023-09-01' })); // TODO: quitar fecha  hardcode TODAY
  };

  return <CustomBox handleClick={handleSendItem} data={data} />;
}

export default SendBox;
