import { Button, Grid } from '@mui/material';
import PageTitle from 'content/features/other/components/PageTitle';
import React from 'react';
import { useDispatch } from 'react-redux';
import { changeIsNew } from '../user.actions';

function UserTitle({ setIsOpenModal }) {
  const dispatch = useDispatch();
  const handleOnClick = () => {
    setIsOpenModal(true);
    dispatch(changeIsNew({ isNew: true }));
  };
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>
        <PageTitle title="Lista usuarios" />
      </Grid>
      <Grid item>
        <Button variant="contained" size="large" onClick={handleOnClick}>
          Crear usuario
        </Button>
      </Grid>
    </Grid>
  );
}
export default UserTitle;
