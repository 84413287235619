import React from 'react';
import UserSearch from './UserSearch';
import UserTable from './UserTable';
import UserTitle from './UserTitle';
import UserModal from './UserModal';
import UserDeleteModal from './UserDeleteModal';

function UserFeed({
  users,
  setIsOpenModal,
  isOpenModal,
  isNew,
  isOpenDeleteModal,
  setIsOpenDeleteModal,
}) {
  return (
    <>
      <UserTitle setIsOpenModal={setIsOpenModal} />
      <UserSearch placeholder="Buscar por nombre" />
      <UserTable users={users} setIsOpen={setIsOpenModal} setIsOpenDelete={setIsOpenDeleteModal} />
      <UserModal isOpen={isOpenModal} setIsOpen={setIsOpenModal} isNew={isNew} />
      <UserDeleteModal isOpen={isOpenDeleteModal} setIsOpen={setIsOpenDeleteModal} />
    </>
  );
}

export default UserFeed;
