import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

/* React Redux */
import { useSelector, useDispatch } from 'react-redux';

import { AppBar, IconButton, Toolbar } from '@mui/material';

/* Project */
import { LoginForm, LoginSelector, LoginSnackbars } from 'content/features/authentication';
import { validateEmail, validatePassword } from 'utils/functions';
import { controlsChanged, login } from 'content/features/authentication/login/login.actions';
import { toggleErrorMessage, toggleSuccessMessage } from '../../app.actions';

const PUCNavbarLogo = `${window.location.origin}/images/logo-uc-01.svg`;

const { page: loginSelector } = LoginSelector;

function Login() {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({ email: [], password: [] });

  const dispatch = useDispatch();
  const states = useSelector(loginSelector);

  const { controls, error, errorMsg, success, successMsg, loading } = states;
  const { email, password } = controls;

  const onChangeText = useCallback(({ target: { id, value } }) => {
    dispatch(controlsChanged({ name: id, value }));
  }, []);

  const toggleError = () => dispatch(toggleErrorMessage());
  const toggleSuccess = () => dispatch(toggleSuccessMessage());

  const validateFields = () => {
    const currentErrors = {
      email: [
        {
          check: email?.trim()?.length > 0,
          message: 'Correo electrónico requerido',
        },
        {
          check: validateEmail(email).check,
          message: 'Correo electrónico inválido',
        },
      ],
      password: [validatePassword(password)],
    };

    const hasFalseCheck = (value) => !value.check;

    const errorsWithTrueCheck = Object.entries(currentErrors)
      .map(([field, properties]) => ({
        [field]: properties.filter(hasFalseCheck),
      }))
      .reduce((result, current) => ({ ...result, ...current }), {});

    setErrors(errorsWithTrueCheck);

    return currentErrors;
  };

  const handleLogin = () => {
    const validAuth = () =>
      Object.values(validateFields()).flatMap((fieldError) =>
        fieldError.filter((properties) => properties.check),
      );

    if (validAuth()) {
      dispatch(login({ email: email.toLowerCase(), password }));
    }
  };

  const content = (
    <LoginForm
      handleLogin={handleLogin}
      controls={controls}
      errors={errors}
      onChangeText={onChangeText}
      loading={loading}
    />
  );

  const snackbars = (
    <LoginSnackbars
      toggleError={toggleError}
      toggleSuccess={toggleSuccess}
      error={error}
      errorMsg={errorMsg}
      success={success}
      successMsg={successMsg}
    />
  );

  return (
    <>
      <AppBar position="static" color="inherit" sx={{ boxShadow: 'none' }}>
        <Toolbar>
          <IconButton
            onClick={() => navigate('/')}
            sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
            <img style={{ height: '85px', marginLeft: '44px' }} src={PUCNavbarLogo} alt="" />
          </IconButton>
        </Toolbar>
      </AppBar>
      {content}
      {snackbars}
    </>
  );
}

export default Login;
