import CustomTable from 'content/features/other/components/CustomTable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { IconButton } from '@mui/material';
import { itemTypeSelector, paginationSelector } from '../itemType.selector';
import { changePagination, setControls } from '../itemType.actions';

function Actions(setModal, modal, dispatch, itemType) {
  const { id, name, priority, bukCode, isOpen, discountType, type } = itemType;
  const handleEdit = () => {
    dispatch(
      setControls({
        id,
        name,
        priority,
        bukCode,
        bukItemCode: bukCode,
        isOpen,
        discountType,
        type,
      }),
    );

    setModal({
      ...modal,
      isOpen: true,
    });
  };
  return (
    <IconButton type="button" onClick={handleEdit}>
      <ArrowCircleRightIcon color="primary" fontSize="large" />
    </IconButton>
  );
}

function ItemTypeTable({ setModal, modal }) {
  const dispatch = useDispatch();
  const itemTypesList = useSelector(itemTypeSelector);
  const paginationOptions = useSelector(paginationSelector);
  const fetchOptions = {
    totalPage: paginationOptions.totalPage,
    actualPage: paginationOptions.currentPage,
  };
  const handleChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };
  const isActive = (itemType) => (itemType.isOpen ? 'Activo' : 'Deshabilitado');
  const data = {
    headers: [
      { name: 'Código descueto', itemKey: 'code' },
      { name: 'Nombre descuento', itemKey: 'name' },
      { name: 'Tipo de descuento', itemKey: 'type' },
      { name: 'Prioridad', itemKey: 'priority' },
      { name: 'Estado', itemKey: 'status' },
      { name: 'Acción', itemKey: 'action', align: 'right' },
    ],
    items: itemTypesList.map((itemType) => ({
      id: itemType.id,
      name: itemType.name,
      code: itemType.bukItemCode,
      type: itemType.discountType.name,
      priority: itemType.priority,
      status: isActive(itemType),
      action: Actions(setModal, modal, dispatch, {
        id: itemType.id,
        name: itemType.name,
        priority: itemType.priority,
        bukCode: itemType.bukItemCode,
        isOpen: itemType.isOpen,
        discountType: itemType.discountType.id,
        type: itemType.discountType.name === 'Haber no descontable' ? 'haber' : 'descuento',
      }),
    })),
  };
  return (
    <CustomTable
      color="primary"
      data={data}
      isPaginated
      paginationOptions={fetchOptions}
      onChange={handleChange}
    />
  );
}

export default ItemTypeTable;
