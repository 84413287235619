import React, { useState } from 'react';
import ItemTypeTitle from './ItemTypeTitle';
import ItemTypeSearch from './ItemTypeSearch';
import ItemTypeTable from './ItemTypeTable';
import ItemTypeModal from './ItemTypeModal';

function ItemTypeFeed() {
  const [modal, setModal] = useState({ isOpen: false, isNew: false });
  return (
    <>
      <ItemTypeTitle setModal={setModal} />
      <ItemTypeSearch />
      <ItemTypeTable setModal={setModal} modal={modal} />
      <ItemTypeModal modal={modal} setModal={setModal} />
    </>
  );
}

export default ItemTypeFeed;
