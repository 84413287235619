import { createReducer } from '@reduxjs/toolkit';

import {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFail,
  changeSearchValue,
  changeIsNew,
  sendUserSuccess,
  sendUser,
  sendUserFail,
  setControl,
  setControls,
  resetControls,
  updateUser,
  updateUserSuccess,
  updateUserFail,
  changePagination,
} from './user.actions';

const initialState = {
  currentPage: 1,
  pageSize: 8,
  totalPages: 1,
  data: [],
  isLoading: false,
  isLoadingSend: false,
  errorSend: null,
  error: null,
  searchValue: '',
  isNew: false,
  controls: {
    id: null,
    name: '',
    email: '',
    rol: 'user',
  },
};

const UserReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchUsers, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchUsersSuccess, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      state.totalPages = action.payload.totalPages;
    })
    .addCase(fetchUsersFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.searchValue = action.payload.searchValue;
      state.currentPage = 1;
    })
    .addCase(changeIsNew, (state, action) => {
      state.isNew = action.payload.isNew;
    })
    .addCase(sendUser, (state) => {
      state.isLoadingSend = true;
      state.errorSend = null;
    })
    .addCase(sendUserSuccess, (state) => {
      state.isLoadingSend = false;
      state.errorSend = null;
    })
    .addCase(sendUserFail, (state, action) => {
      state.isLoadingSend = false;
      state.errorSend = action.payload.error;
    })
    .addCase(updateUser, (state) => {
      state.isLoadingSend = true;
      state.errorSend = null;
    })
    .addCase(updateUserSuccess, (state) => {
      state.isLoadingSend = false;
    })
    .addCase(updateUserFail, (state) => {
      state.isLoadingSend = false;
    })
    .addCase(changePagination, (state, action) => {
      state.currentPage = action.payload.currentPage;
    })
    .addCase(setControl, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(setControls, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(resetControls, (state) => {
      state.controls = { ...initialState.controls };
    });
});

export default UserReducer;
