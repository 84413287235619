import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  updatePassword,
  updatePasswordFail,
  updatePasswordSuccess,
} from './changePassword.actions';

function* changePassword() {
  yield takeLatest(updatePassword, function* updateDefaultPassword(action) {
    const { user, password, navigate } = action.payload;
    const { email, name, type, id } = user;
    const body = JSON.stringify({
      email,
      name,
      password,
      type,
      isActive: true,
      haveDefaultPassword: false,
    });

    const response = yield apiRequest(`api/v1/users/${id}`, {
      method: 'PATCH',
      body,
    });
    if (response) {
      defaultSuccessToast({ message: 'Contraseña actualizada correctamente.' });
      yield put(updatePasswordSuccess());
      navigate('/');
    } else {
      defaultErrorToast({ message: response.message });
      yield put(updatePasswordFail());
    }
  });
}

export default function* ChangePasswordSaga() {
  yield spawn(changePassword);
}
