import React from 'react';
import PropTypes from 'prop-types';

/* React Router */
import { useNavigate } from 'react-router-dom';

/* Material UI */
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Button,
  Box,
  Menu,
  MenuItem,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

// Project
import navbarStyles from './navbar.styles';

const PUCNavbarLogo = `${window.location.origin}/images/logo-uc-01.svg`;

function NavBar(props) {
  const { setOpenDrawer, handleLogout, user } = props;
  const navigate = useNavigate();

  function sessionOption() {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    if (user) {
      return (
        <Box display="flex" alignItems="center">
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClick}
            style={{ textTransform: 'lowercase' }}>
            <Box
              display="flex"
              alignItems="center"
              backgroundColor="#C4C4C4"
              borderRadius="15px"
              padding="6px"
              marginRight="10px">
              <AccountCircleOutlinedIcon style={{ color: '#fff' }} />
            </Box>
            <Typography fontWeight="bold" color="#173F8A">
              {user.email}
            </Typography>
          </Button>
          <Menu id="simple-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            <MenuItem onClick={() => handleLogout()}>Cerrar sesión</MenuItem>
          </Menu>
        </Box>
      );
    }

    return (
      <Button onClick={() => navigate('/login')} color="inherit">
        Iniciar sesión
      </Button>
    );
  }

  return (
    <AppBar position="static" color="inherit" sx={{ boxShadow: 'none' }}>
      <Toolbar>
        <IconButton
          data-testid="toolbar-menu-button"
          onClick={() => setOpenDrawer(true)}
          edge="start"
          sx={navbarStyles.menuButton}
          color="inherit"
          aria-label="menu">
          <MenuIcon />
        </IconButton>

        <IconButton
          onClick={() => navigate('/')}
          sx={{ '&:hover': { backgroundColor: 'transparent' } }}>
          <img style={{ height: '80px' }} src={PUCNavbarLogo} alt="" />
        </IconButton>

        <Typography variant="h6" sx={navbarStyles.title} />

        {sessionOption()}
      </Toolbar>
    </AppBar>
  );
}

NavBar.propTypes = {
  setOpenDrawer: PropTypes.func.isRequired,
  handleLogout: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

NavBar.defaultProps = {
  user: null,
};

export default NavBar;
