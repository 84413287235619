const BASE = '[DetailsPayroll]';

const DetailsPayrollTypes = {
  FETCH_EMPLOYEE: `${BASE} FETCH_EMPLOYEE`,
  FETCH_EMPLOYEE_SUCCESS: `${BASE} FETCH_EMPLOYEE_SUCCESS`,
  FETCH_EMPLOYEE_FAIL: `${BASE} FETCH_EMPLOYEE_FAIL`,
  CLEAR_SELECTED_EMPLOYEE: `${BASE} CLEAR_SELECTED_EMPLOYEE`,
  UPDATE_EMPLOYEE_PAYROLL: `${BASE} UPDATE_EMPLOYEE_PAYROLL`,
  UPDATE_EMPLOYEE_PAYROLL_SUCCESS: `${BASE} UPDATE_EMPLOYEE_PAYROLL_SUCCESS`,
  UPDATE_EMPLOYEE_PAYROLL_FAIL: `${BASE} UPDATE_EMPLOYEE_PAYROLL_FAIL`,
  SEND_EMPLOYEE_PAYROLL: `${BASE} SEND_EMPLOYEE_PAYROLL`,
  SEND_EMPLOYEE_PAYROLL_SUCCESS: `${BASE} SEND_EMPLOYEE_PAYROLL_SUCCESS`,
  SEND_EMPLOYEE_PAYROLL_FAIL: `${BASE} SEND_EMPLOYEE_PAYROLL_FAIL`,
  DELETE_ITEM_BALANCE: `${BASE} DELETE_ITEM_BALANCE`,
  DELETE_ITEM_BALANCE_SUCCESS: `${BASE} DELETE_ITEM_BALANCE_SUCCESS`,
  DELETE_ITEM_BALANCE_FAIL: `${BASE} DELETE_ITEM_BALANCE_FAIL`,
};

export default DetailsPayrollTypes;
