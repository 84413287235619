import { createAction } from '@reduxjs/toolkit';
import ItemTypeTypes from './itemType.types';

export const fetchItemType = createAction(ItemTypeTypes.FETCH_ITEM_TYPE);

export const fetchItemTypeSuccess = createAction(ItemTypeTypes.FETCH_ITEM_TYPE_SUCCESS);

export const fetchItemTypeFail = createAction(ItemTypeTypes.FETCH_ITEM_TYPE_FAIL);

export const fetchDiscountType = createAction(ItemTypeTypes.FETCH_DISCOUNT_TYPE);

export const fetchDiscountTypeSuccess = createAction(ItemTypeTypes.FETCH_DISCOUNT_TYPE_SUCCESS);

export const fetchDiscountTypeFail = createAction(ItemTypeTypes.FETCH_DISCOUNT_TYPE_FAIL);

export const createItemType = createAction(ItemTypeTypes.CREATE_ITEM_TYPE);

export const createItemTypeSuccess = createAction(ItemTypeTypes.CREATE_ITEM_TYPE_SUCCESS);

export const createItemTypeFail = createAction(ItemTypeTypes.CREATE_ITEM_TYPE_FAIL);

export const updateItemType = createAction(ItemTypeTypes.UPDATE_ITEM_TYPE);

export const updateItemTypeSuccess = createAction(ItemTypeTypes.UPDATE_ITEM_TYPE_SUCCESS);

export const updateItemTypeFail = createAction(ItemTypeTypes.UPDATE_ITEM_TYPE_FAIL);

export const changeSearchValue = createAction(ItemTypeTypes.CHANGE_SEARCH_VALUE);

export const resetSearchValue = createAction(ItemTypeTypes.RESET_SEARCH_VALUE);

export const changePagination = createAction(ItemTypeTypes.CHANGE_PAGINATION);

export const setControls = createAction(ItemTypeTypes.SET_CONTROLS);

export const setControl = createAction(ItemTypeTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));

export const resetControls = createAction(ItemTypeTypes.RESET_CONTROLS);

const ItemTypeActions = {
  fetchItemType,
  fetchItemTypeSuccess,
  fetchItemTypeFail,
  changeSearchValue,
  resetSearchValue,
  changePagination,
  setControls,
  setControl,
};

export default ItemTypeActions;
