import apiRequest from 'utils/api';
import { put, spawn, takeLatest } from 'redux-saga/effects';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchItemType,
  fetchItemTypeSuccess,
  fetchItemTypeFail,
  fetchDiscountType,
  fetchDiscountTypeSuccess,
  fetchDiscountTypeFail,
  createItemType,
  createItemTypeSuccess,
  createItemTypeFail,
  updateItemType,
  updateItemTypeFail,
  updateItemTypeSuccess,
} from './itemType.actions';

function* getItemType() {
  yield takeLatest(fetchItemType, function* getItemTypeToApi(action) {
    const { searchValue, pageSize, actualPage } = action.payload;
    const response = yield apiRequest(
      `api/v1/item-type?page=${actualPage}&take=${pageSize}&search=${searchValue}`,
      {
        method: 'GET',
      },
    );
    if (response) {
      yield put(
        fetchItemTypeSuccess({
          data: response.objects,
          totalPages: response.pagination.totalPages,
          actualPage: response.pagination.currentPage,
        }),
      );
    } else {
      yield put(fetchItemTypeFail({ error: response.message }));
    }
  });
}

function* getDiscountType() {
  yield takeLatest(fetchDiscountType, function* getDiscountTypeToApi() {
    const response = yield apiRequest('api/v1/item-type/discount-type', {
      method: 'GET',
    });
    if (response) {
      yield put(
        fetchDiscountTypeSuccess({
          data: response,
        }),
      );
    } else {
      yield put(fetchDiscountTypeFail({ error: response.message }));
    }
  });
}

function* postItemType() {
  yield takeLatest(createItemType, function* postItemTypeToApi(action) {
    const body = JSON.stringify(action.payload);
    const response = yield apiRequest('api/v1/item-type', {
      method: 'POST',
      body,
    });
    if (!response) {
      yield put(createItemTypeFail({ error: response.message }));
      defaultErrorToast({ message: response.message });
    } else {
      yield put(createItemTypeSuccess());
      defaultSuccessToast({ message: 'Descuento creado con exito' });
    }
  });
}

function* putItemType() {
  yield takeLatest(updateItemType, function* putItemTypeToApi(action) {
    const body = JSON.stringify(action.payload);
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/item-type/${id}`, {
      method: 'PATCH',
      body,
    });
    if (!response) {
      yield put(updateItemTypeFail({ error: response.message }));
      defaultErrorToast({ message: 'Error al actualizar descuento' });
    } else {
      yield put(updateItemTypeSuccess());
      defaultSuccessToast({ message: 'Descuento actualizado correctamente' });
    }
  });
}

export default function* ItemTypeSaga() {
  yield spawn(getItemType);
  yield spawn(getDiscountType);
  yield spawn(postItemType);
  yield spawn(putItemType);
}
