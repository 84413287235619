import { createAction } from '@reduxjs/toolkit';
import DiscountsTypes from './discounts.types';

export const fetchDiscounts = createAction(DiscountsTypes.FETCH_DISCOUNTS);

export const fetchDiscountsSuccess = createAction(
  DiscountsTypes.FETCH_DISCOUNTS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
      totalPages: payload.totalPages,
      currentPage: payload.currentPage,
    },
  }),
);

export const fetchDiscountsFail = createAction(DiscountsTypes.FETCH_DISCOUNTS_FAIL);

export const fetchScoreCardDiscounts = createAction(DiscountsTypes.FETCH_SCORE_CARD_DISCOUNTS);

export const fetchScoreCardDiscountsSuccess = createAction(
  DiscountsTypes.FETCH_SCORE_CARD_DISCOUNTS_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchScoreCardDiscountsFail = createAction(
  DiscountsTypes.FETCH_SCORE_CARD_DISCOUNTS_FAIL,
);

export const changePagination = createAction(DiscountsTypes.CHANGE_PAGINATION, (payload) => ({
  payload: {
    ...payload,
    currentPage: payload.currentPage,
  },
}));

export const changeSearchValue = createAction(DiscountsTypes.CHANGE_SEARCH_VALUE, (payload) => ({
  payload: {
    ...payload,
    searchValue: payload.searchValue,
  },
}));

export const changeStartPeriodValue = createAction(
  DiscountsTypes.CHANGE_START_PERIOD,
  (payload) => ({
    payload: {
      ...payload,
      startPeriodValue: payload.startPeriodValue,
    },
  }),
);

export const changeEndPeriodValue = createAction(DiscountsTypes.CHANGE_END_PERIOD, (payload) => ({
  payload: {
    ...payload,
    endPeriodValue: payload.endPeriodValue,
  },
}));

export const changeProcessValue = createAction(DiscountsTypes.CHANGE_PROCESS, (payload) => ({
  payload: {
    ...payload,
    processValue: payload.processValue,
  },
}));

export const getReport = createAction(DiscountsTypes.GET_REPORT);

export const getReportSuccess = createAction(DiscountsTypes.GET_REPORT_SUCCESS);

export const getReportFail = createAction(DiscountsTypes.GET_REPORT_FAIL);

const DiscontsActions = {
  fetchDiscounts,
  fetchDiscountsSuccess,
  fetchDiscountsFail,
  changePagination,
  changeSearchValue,
  changeStartPeriodValue,
  changeEndPeriodValue,
  changeProcessValue,
  getReport,
  getReportSuccess,
  getReportFail,
};

export default DiscontsActions;
