import { indigo } from '@mui/material/colors';

/**
 * @type {import('@mui/material').ThemeOptions}
 */
const defaultTheme = {
  palette: {
    primary: {
      main: '#0176de',
      contrastText: '#fff',
    },
    secondary: {
      main: '#feda62',
    },
    title: {
      main: indigo[900],
      contrastText: '#fff',
    },
    text: {
      title: 'rgb(112, 76, 182)',
      number: 'rgb(81,82,82)',
    },
    yellow: {
      main: '#FEC60D',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
};

export default defaultTheme;
