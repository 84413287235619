import { Button, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback } from 'react';
import CustomDivider from 'content/features/other/components/CustomDivider';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'hooks/useForm';
import { controlsSelector, discountTypeSelector } from '../itemType.selector';
import { createItemType, resetControls, setControl, updateItemType } from '../itemType.actions';

function ItemTypeForm({ modal, setModal }) {
  const dispatch = useDispatch();
  const controls = useSelector(controlsSelector);
  const discountTypes = useSelector(discountTypeSelector);
  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validateOnChange: true,
    validations: {
      name: [
        {
          check: (value) => !!value,
          message: 'El nombre es requerido',
        },
      ],
      priority: [
        {
          check: (value) => !!value,
          message: 'La prioridad es requerida',
        },
      ],
      bukCode: [
        {
          check: (value) => !!value,
          message: 'El código es requerido',
        },
      ],
      discountType: [
        {
          check: (value) => !!value,
          message: 'El tipo de descuento es requerido',
        },
      ],
    },
  });

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);

    if (
      event.target.value === discountTypes.find(({ name }) => name === 'Haber no descontable').id
    ) {
      dispatch(
        setControl({
          name: 'type',
          value: 'haber',
        }),
      );
    }
  };
  const handleIsNew = () => {
    let title;
    let buttons;
    let handleSubmit;
    const handleSave = () => {
      dispatch(
        updateItemType({
          id: controls.id,
          name: controls.name,
          priority: controls.priority,
          bukItemCode: controls.bukCode,
          isOpen: controls.isOpen,
          discountType: {
            id: controls.discountType,
          },
        }),
      );
      setModal({
        isOpen: false,
        isNew: false,
      });
      dispatch(resetControls());
    };

    const handleDisable = () => {
      dispatch(
        updateItemType({
          id: controls.id,
          name: controls.name,
          priority: controls.priority,
          bukCode: controls.bukItemCode,
          isOpen: false,
          discountType: {
            id: controls.discountType,
          },
        }),
      );
      setModal({
        isOpen: false,
        isNew: false,
      });
      dispatch(resetControls());
    };
    if (modal.isNew) {
      title = 'Crear descuento';
      buttons = (
        <Grid item md={12}>
          <Button variant="contained" color="secondary" fullWidth type="submit">
            Crear descuento
          </Button>
        </Grid>
      );
      handleSubmit = () => {
        dispatch(
          createItemType({
            name: controls.name,
            priority: controls.priority,
            bukType: controls.type,
            bukSubtype: controls.type === 'haber' ? 'remuneracion' : 'otro_descuento',
            bukCode: controls.bukCode,
            bukItemCode: controls.bukCode,
            discountType: {
              id: controls.discountType,
            },
            isOpen: true,
          }),
        );
        setModal({
          isOpen: false,
          isNew: false,
        });
        dispatch(resetControls());
      };
    } else {
      title = 'Editar descuento';
      buttons = (
        <>
          <Grid item md={12}>
            <Button variant="contained" color="secondary" onClick={handleSave} fullWidth>
              Guardar
            </Button>
          </Grid>
          <Grid item md={12}>
            <Button variant="contained" color="error" onClick={handleDisable} fullWidth>
              Deshabilitar descuento
            </Button>
          </Grid>
        </>
      );
    }
    return { title, buttons, handleSubmit };
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 846,
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
  };
  const handleClose = () => {
    setModal({
      isOpen: false,
      isNew: false,
    });
    dispatch(resetControls());
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    onSubmit(handleIsNew().handleSubmit);
    setModal({
      isOpen: false,
      isNew: false,
    });
    dispatch(resetControls());
  };
  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent="center" sx={style}>
        <Grid item container md={12} justifyContent="flex-end">
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={8}>
          <Grid item md={12} pb={5}>
            <Typography variant="h4" fontWeight="bold">
              {handleIsNew().title}
            </Typography>
            <CustomDivider color="secondary" type="title" />
          </Grid>
          <Grid item container spacing={2}>
            <Grid item md={6}>
              <Typography variant="caption" fontWeight="bold">
                Nombre*
              </Typography>
              <TextField
                label="Nombre"
                key="name"
                id="name"
                type="name"
                name="name"
                value={controls.name}
                onChange={handleChange}
                style={{ paddingBottom: '8px', marginBottom: '5%' }}
                fullWidth
                error={!!errors?.name[0]}
                helperText={errors?.name[0]}
              />
            </Grid>
            <Grid item md={6}>
              <Typography variant="caption" fontWeight="bold">
                Prioridad*
              </Typography>
              <TextField
                label="Prioridad"
                key="priority"
                id="priority"
                type="priority"
                name="priority"
                value={controls.priority}
                onChange={handleChange}
                style={{ paddingBottom: '8px', marginBottom: '5%' }}
                fullWidth
                error={!!errors?.priority[0]}
                helperText={errors?.priority[0]}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="caption" fontWeight="bold">
                Código de descuento*
              </Typography>
              <TextField
                label="Código"
                key="bukCode"
                id="bukCode"
                type="bukCode"
                name="bukCode"
                value={controls.bukCode}
                onChange={handleChange}
                style={{ paddingBottom: '8px', marginBottom: '5%' }}
                fullWidth
                error={!!errors?.bukCode[0]}
                helperText={errors?.bukCode[0]}
              />
            </Grid>
            <Grid item md={12}>
              <Typography variant="caption" fontWeight="bold" width="100%">
                Tipo de descuento*
              </Typography>

              <Select
                defaultValue={discountTypes[0].id}
                sx={{ backgroundColor: '#FFF', width: '100%' }}
                value={controls.discountType}
                key="discountType"
                id="discountType"
                type="discountType"
                name="discountType"
                onChange={handleChange}>
                {discountTypes.map((discountType) => (
                  <MenuItem value={discountType.id}>{discountType.name}</MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid container item md={12} pt={6} pb={6} spacing={2}>
              {handleIsNew().buttons}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </form>
  );
}

export default ItemTypeForm;
