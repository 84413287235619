import { Modal } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import UserForm from './UserForm';
import { changeIsNew, resetControls } from '../user.actions';

function UserModal({ isOpen, setIsOpen, isNew, controls, onChangeText, nameUser, emailUser }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setIsOpen(false);
    dispatch(resetControls());
    dispatch(changeIsNew({ isNew: false }));
  };
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <UserForm
          setIsOpen={setIsOpen}
          isNew={isNew}
          controls={controls}
          onChangeText={onChangeText}
          nameUser={nameUser}
          emailUser={emailUser}
        />
      </Modal>
    </div>
  );
}

export default UserModal;
