import { Modal } from '@mui/material';
import { ConfirmDialog } from 'content/shared';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { controlsSelector } from '../user.selector';
import { changePagination, resetControls, updateUser } from '../user.actions';

function UserDeleteModal({ isOpen, setIsOpen }) {
  const dispatch = useDispatch();
  const controls = useSelector(controlsSelector);
  const handleCloseConfirm = () => {
    dispatch(
      updateUser({
        id: controls.id,
        name: controls.name,
        email: controls.email,
        rol: controls.rol,
        isActive: false,
        message: 'eliminado',
      }),
    );
    dispatch(changePagination({ currentPage: 1 }));
    dispatch(resetControls());
    setIsOpen(false);
  };
  const handleClose = () => {
    dispatch(resetControls());
    setIsOpen(false);
  };
  return (
    <div>
      <Modal open={isOpen} onClose={handleClose}>
        <ConfirmDialog
          open={isOpen}
          message={`¿Estás seguro de que quieres eliminar al usuario ${controls.name} ?`}
          severity="warning"
          onCloseConfirm={handleCloseConfirm}
          onClose={handleClose}
        />
      </Modal>
    </div>
  );
}

export default UserDeleteModal;
