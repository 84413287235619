import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import api from 'utils/api';
import {
  login,
  loginSuccess,
  loginFail,
  googleLogin,
  googleLoginSuccess,
  googleLoginFail,
  logout,
  logoutSuccess,
} from './login.actions';

function* loginUser() {
  yield takeLatest(login, function* loginUserApi(action) {
    const body = JSON.stringify(action.payload);
    const response = yield api('api/v1/auth/login', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem('user', JSON.stringify({ token: response.token }));

      yield put(loginSuccess({ user: response.user }));
    } else {
      yield put(loginFail());
      defaultErrorToast({ message: 'Credenciales incorrectas' });
    }
  });
}

function* loginUserByGoogle() {
  yield takeLatest(googleLogin, function* fetchGoogleLogin(action) {
    const body = JSON.stringify(action.payload);

    const response = yield api('api/v1/auth/google', { method: 'post', body });

    if (!response.error) {
      localStorage.setItem(
        'user',
        JSON.stringify({
          token: response.token,
        }),
      );

      yield put(
        googleLoginSuccess({
          user: response.user,
        }),
      );
    } else {
      yield put(googleLoginFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

const removeGoogleId = ({ user }) => {
  if (window.google) {
    window.google.accounts.id.revoke(user.email);
  }
};

function* logoutUser() {
  yield takeLatest(logout, function* logoutLocalUser({ payload }) {
    const { user, signedByGoogle } = payload;

    if (signedByGoogle) {
      removeGoogleId({ signedByGoogle, user });
    }

    localStorage.clear();

    yield put(logoutSuccess());
  });
}

export default function* LoginSaga() {
  yield spawn(loginUser);
  yield spawn(logoutUser);
  yield spawn(loginUserByGoogle);
}
