import { createAction } from '@reduxjs/toolkit';

import UserTypes from './user.types';

export const fetchUsers = createAction(UserTypes.FETCH_USERS);

export const fetchUsersSuccess = createAction(UserTypes.FETCH_USERS_SUCCESS, (payload) => ({
  payload: {
    ...payload,
    totalPages: payload.totalPages,
    currentPage: payload.currentPage,
  },
}));

export const fetchUsersFail = createAction(UserTypes.FETCH_USERS_FAIL);

export const changeSearchValue = createAction(UserTypes.CHANGE_SEARCH_VALUE);

export const changeIsNew = createAction(UserTypes.CHANGE_IS_NEW);

export const changePagination = createAction(UserTypes.CHANGE_PAGINATION, (payload) => ({
  payload: {
    ...payload,
    currentPage: payload.currentPage,
  },
}));

export const sendUser = createAction(UserTypes.SEND_USER);

export const sendUserSuccess = createAction(UserTypes.SEND_USER_SUCCESS);

export const sendUserFail = createAction(UserTypes.SEND_USER_ERROR);

export const updateUser = createAction(UserTypes.UPDATE_USER);

export const updateUserSuccess = createAction(UserTypes.UPDATE_USER_SUCCESS);

export const updateUserFail = createAction(UserTypes.UPDATE_USER_FAIL);

export const setControls = createAction(UserTypes.SET_CONTROLS, (payload) => ({
  payload: {
    ...payload,
  },
}));

export const setControl = createAction(UserTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));
export const resetControls = createAction(UserTypes.RESET_CONTROLS);

const UserActions = {
  fetchUsers,
  fetchUsersSuccess,
  fetchUsersFail,
  changeSearchValue,
  changeIsNew,
  changePagination,
  sendUser,
  sendUserSuccess,
  sendUserFail,
  setControls,
  setControl,
  resetControls,
};

export default UserActions;
