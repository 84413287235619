const BASE = '[BulkLoad]';

const BulkLoadTypes = {
  GET_PAYROLL: `${BASE} GET_PAYROLL`,
  GET_PAYROLL_SUCCESS: `${BASE} GET_PAYROLL_SUCCESS`,
  GET_PAYROLL_FAIL: `${BASE} GET_PAYROLL_FAIL`,
  LOADING_PROCESS: `${BASE} LOADING_PROCESS`,
  INSERTED_RECORD: `${BASE} INSERTED_RECORD`,
  GET_LAST_PERIOD: `${BASE} GET_LAST_PERIOD`,
  GET_LAST_PERIOD_SUCCESS: `${BASE} GET_LAST_PERIOD_SUCCESS`,
  GET_LAST_PERIOD_FAIL: `${BASE} GET_LAST_PERIOD_FAIL`,
  SET_LOAD_DATE: `${BASE} SET_LOAD_DATE`,

  SEND_ITEMS: `${BASE} SEND_ITEMS`,
  SEND_ITEMS_SUCCESS: `${BASE} SEND_ITEMS_SUCCESS`,
  SEND_ITEMS_FAIL: `${BASE} SEND_ITEMS_FAIL`,
  SENDING_PROCESS: `${BASE} SENDING_PROCESS`,
  UPDATED_RECORD: `${BASE} UPDATED_RECORD`,
  GET_LAST_UPDATE: `${BASE} GET_LAST_UPDATE`,
  GET_LAST_UPDATE_SUCCESS: `${BASE} GET_LAST_UPDATE_SUCCESS`,
  GET_LAST_UPDATE_FAIL: `${BASE} GET_LAST_UPDATE_FAIL`,
  SET_SEND_DATE: `${BASE} SET_SEND_DATE`,

  GET_CLOSED_PAYROLL_LOAD: `${BASE} GET_CLOSED_PAYROLL_LOAD`,
  GET_CLOSED_PAYROLL_SEND: `${BASE} GET_CLOSED_PAYROLL_SEND`,
  GET_CLOSED_PAYROLL_SUCCESS: `${BASE} GET_CLOSED_PAYROLL_SUCCESS`,
  GET_CLOSED_PAYROLL_FAIL: `${BASE} GET_CLOSED_PAYROLL_FAIL`,
};

export default BulkLoadTypes;
