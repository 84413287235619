export const itemTypeSelector = (state) => state.itemType.data;
export const searchValueSelector = (state) => state.itemType.searchValue;
export const paginationSelector = (state) => state.itemType.pagination;
export const isLoadingFetchSelector = (state) => state.itemType.isLoadingFetch;
export const isLoadingCreateSelector = (state) => state.itemType.isLoadingCreate;
export const isLoadingUpdateSelector = (state) => state.itemType.isLoadingUpdate;
export const controlsSelector = (state) => state.itemType.controls;
export const discountTypeSelector = (state) => state.itemType.discountType;

const ItemTypeSelector = {
  itemTypeData: itemTypeSelector,
  searchValue: searchValueSelector,
  pagination: paginationSelector,
  isLoadingFetch: isLoadingFetchSelector,
  controls: controlsSelector,
  discountType: discountTypeSelector,
  isLoadingCreate: isLoadingCreateSelector,
  isLoadingUpdate: isLoadingUpdateSelector,
};

export default ItemTypeSelector;
