import React, { useEffect } from 'react';

/* Material UI */
import { Grid, Stack } from '@mui/material';

/* Project */
import CustomCard from 'content/features/other/components/CustomCard';
import PageTitle from 'content/features/other/components/PageTitle';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeEndPeriodValue,
  changePagination,
  changeProcessValue,
  changeSearchValue,
  changeStartPeriodValue,
  fetchDiscount,
  fetchDiscountEmployees,
  fetchDiscountScoreCard,
} from 'content/features/details-discounts/discountEmployee.action';
import { formatNumber, formatRut } from 'utils/functions';
import CustomTable from 'content/features/other/components/CustomTable';
import {
  currentPageSelector,
  discountSelector,
  endPeriodValueSelector,
  processValueSelector,
  searchValueSelector,
  startPeriodValueSelector,
  totalPagesSelector,
} from 'content/features/details-discounts/discountEmployee.selector';
import SearchBar from 'content/features/other/components/SearchBar';

function DetailsDiscount() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const itemData = useSelector(discountSelector);
  const currentPage = useSelector(currentPageSelector);
  const totalPage = useSelector(totalPagesSelector);
  const searchValue = useSelector(searchValueSelector);
  const startPeriodValue = useSelector(startPeriodValueSelector);
  const endPeriodValue = useSelector(endPeriodValueSelector);
  const processValue = useSelector(processValueSelector);

  const fetchOptions = {
    id,
    date: '01-09-2023', // TODO: PRIMER DIA DEL MES ACTUAL
    totalPage,
    actualPage: currentPage,
    pageSize: 10,
    searchValue,
    startPeriodValue,
    endPeriodValue,
    processValue,
  };

  useEffect(() => {
    dispatch(fetchDiscount({ id }));
    dispatch(fetchDiscountEmployees(fetchOptions));
    dispatch(fetchDiscountScoreCard({ id, startPeriodValue, endPeriodValue, processValue }));
  }, [currentPage, searchValue, startPeriodValue, endPeriodValue, processValue]);

  const handleOnChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };

  const handleOnSearch = (value) => {
    dispatch(changeSearchValue({ searchValue: value }));
  };

  const handleOnStartPeriod = (value) => {
    dispatch(changeStartPeriodValue({ startPeriodValue: value }));
  };

  const handleOnEndPeriod = (value) => {
    dispatch(changeEndPeriodValue({ endPeriodValue: value }));
  };

  const handleOnProcess = (value) => {
    dispatch(changeProcessValue({ processValue: value }));
  };

  const data = {
    headers: [
      { itemKey: 'DNI', name: 'Rut' },
      { itemKey: 'name', name: 'Nombre' },
      { itemKey: 'previousBalanceAmount', name: 'Monto acumulado anterior', align: 'right' },
      { itemKey: 'asignedMonthAmount', name: 'Monto asignado mes', align: 'right' },
      { itemKey: 'discountedAmount', name: 'Monto descontado', align: 'right' },
      { itemKey: 'actualBalanceAmount', name: 'Monto no descontado', align: 'right' },
    ],
    items: itemData.employees?.map((item) => ({
      id: item.id,
      DNI: formatRut(item.bukRut),
      name: item.bukName,
      previousBalanceAmount: `$ ${formatNumber(item.previousBalanceAmount)}`,
      asignedMonthAmount: `$ ${formatNumber(
        Math.abs(
          parseInt(item.discountedAmount, 10) +
            parseInt(item.actualBalanceAmount, 10) -
            parseInt(item.previousBalanceAmount, 10),
        ),
      )}`,
      discountedAmount: `$ ${formatNumber(item.discountedAmount)}`,
      actualBalanceAmount: `$ ${formatNumber(item.actualBalanceAmount)}`,
    })),
  };

  return (
    <>
      <PageTitle title="Detalle descuentos" displayBackButton="true" />
      <SearchBar
        placeholder="Nombre o RUT"
        onChangeSearch={handleOnSearch}
        onChangeStartPeriod={handleOnStartPeriod}
        onChangeEndPeriod={handleOnEndPeriod}
        onChangeProcess={handleOnProcess}
      />
      <Grid container justifyContent="space-between" pt={2} pb={2}>
        <Stack direction="row" spacing={2}>
          <CustomCard title="Cod. item" content={itemData.item.bukItemCode} color="primary" />
          <CustomCard title="Descripción" content={itemData.item.name} color="primary" />
        </Stack>
        <Stack direction="row" spacing={2}>
          <CustomCard
            title="Acumulado anterior"
            content={`$ ${formatNumber(itemData.scordCard.previousBalanceAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Asignado del mes"
            content={`$ ${formatNumber(itemData.scordCard.asignedMonthAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Descontado"
            content={`$ ${formatNumber(itemData.scordCard.discountedAmount)}`}
            color="primary"
          />
          <CustomCard
            title="No descontado"
            content={`$ ${formatNumber(itemData.scordCard.actualBalanceAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Cuadratura"
            content={`$ ${formatNumber(
              itemData.scordCard.previousBalanceAmount +
                itemData.scordCard.asignedMonthAmount -
                itemData.scordCard.discountedAmount -
                itemData.scordCard.actualBalanceAmount,
            )}`}
            color="primary"
          />
        </Stack>
      </Grid>
      <CustomTable
        color="primary"
        data={data}
        headersFontWeight="bold"
        isPaginated
        paginationOptions={fetchOptions}
        onChange={handleOnChange}
      />
    </>
  );
}

export default DetailsDiscount;
