const BASE = '[ItemType]';

const ItemTypeTypes = {
  FETCH_ITEM_TYPE: `${BASE} FETCH_ITEM_TYPE`,
  FETCH_ITEM_TYPE_SUCCESS: `${BASE} FETCH_ITEM_TYPE_SUCCESS`,
  FETCH_ITEM_TYPE_FAIL: `${BASE} FETCH_ITEM_TYPE_FAIL`,
  FETCH_DISCOUNT_TYPE: `${BASE} FETCH_DISCOUNT_TYPE`,
  FETCH_DISCOUNT_TYPE_SUCCESS: `${BASE} FETCH_DISCOUNT_TYPE_SUCCESS`,
  FETCH_DISCOUNT_TYPE_FAIL: `${BASE} FETCH_DISCOUNT_TYPE_FAIL`,
  UPDATE_ITEM_TYPE: `${BASE} UPDATE_ITEM_TYPE`,
  UPDATE_ITEM_TYPE_SUCCESS: `${BASE} UPDATE_ITEM_TYPE_SUCCESS`,
  UPDATE_ITEM_TYPE_FAIL: `${BASE} UPDATE_ITEM_TYPE_FAIL`,
  CREATE_ITEM_TYPE: `${BASE} CREATE_ITEM_TYPE`,
  CREATE_ITEM_TYPE_SUCCESS: `${BASE} CREATE_ITEM_TYPE_SUCCESS`,
  CREATE_ITEM_TYPE_FAIL: `${BASE} CREATE_ITEM_TYPE_FAIL`,
  CHANGE_SEARCH_VALUE: `${BASE} CHANGE_SEARCH_VALUE`,
  RESET_SEARCH_VALUE: `${BASE} RESET_SEARCH_VALUE`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  SET_CONTROLS: `${BASE} SET_CONTROLS`,
  SET_CONTROL: `${BASE} SET_CONTROL`,
  RESET_CONTROLS: `${BASE} RESET_CONTROLS`,
};

export default ItemTypeTypes;
