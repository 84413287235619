import { Box } from '@mui/material';
import SearchInput from 'content/features/other/components/SearchInput';
import React, { useEffect } from 'react';
import useUser from '../user.hooks';

function UserSearch({ placeholder }) {
  const { setSearchValue } = useUser();

  const handleOnChange = (value) => {
    setSearchValue(value);
  };
  useEffect(
    () => () => {
      handleOnChange('');
    },
    [],
  );

  return (
    <Box bgcolor="#F0F0F0" p={3} borderRadius={2} alignItems="center" spacing={2}>
      <SearchInput placeholder={placeholder} onChange={handleOnChange} />
    </Box>
  );
}

export default UserSearch;
