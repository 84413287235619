export const discountSelector = (state) => state.discountEmployee.data;
export const scordCardDataSelector = (state) => state.discountEmployee.data.scordCard;
export const loadingSelector = (state) => state.discountEmployee.loaders.page;
export const currentPageSelector = (state) => state.discountEmployee.currentPage;
export const totalPagesSelector = (state) => state.discountEmployee.totalPages;
export const searchValueSelector = (state) => state.discountEmployee.searchValue;
export const startPeriodValueSelector = (state) => state.discountEmployee.startPeriodValue;
export const endPeriodValueSelector = (state) => state.discountEmployee.endPeriodValue;
export const processValueSelector = (state) => state.discountEmployee.processValue;

const DiscountEmployeeSelector = {
  loading: loadingSelector,
  discount: discountSelector,
  scordCardData: scordCardDataSelector,
  currentPage: currentPageSelector,
  totalPages: totalPagesSelector,
  searchValue: searchValueSelector,
  startPeriodValue: startPeriodValueSelector,
  endPeriodValue: endPeriodValueSelector,
  processValue: processValueSelector,
};

export default DiscountEmployeeSelector;
