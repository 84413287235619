import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconButton } from '@mui/material';
import CustomTable from 'content/features/other/components/CustomTable';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changePagination, setControls } from '../user.actions';
import { currentPageSelector, totalPagesSelector } from '../user.selector';

function Actions({ id, name, email, type: rol }, setIsOpen, setIsOpenDelete, loginUser, dispatch) {
  const handleEdit = () => {
    dispatch(setControls({ id, name, email, rol }));
    setIsOpen(true);
  };

  const handleDelete = () => {
    dispatch(setControls({ id, name, email, rol }));
    setIsOpenDelete(true);
  };

  return (
    <>
      <IconButton onClick={handleEdit}>
        <EditIcon />
      </IconButton>
      {loginUser?.id !== id && (
        <IconButton onClick={handleDelete}>
          <DeleteIcon />
        </IconButton>
      )}
    </>
  );
}

function UserTable({ users, setIsOpen, setIsOpenDelete }) {
  const loginUser = useSelector((state) => state.app.user);
  const totalPage = useSelector(totalPagesSelector);
  const currentPage = useSelector(currentPageSelector);

  const dispatch = useDispatch();

  const handleOnChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };

  const fetchOptions = {
    totalPage,
    actualPage: currentPage || 1,
  };

  const data = {
    headers: [
      { name: 'Nombre', itemKey: 'name' },
      { name: 'Email', itemKey: 'email' },
      { name: 'Rol', itemKey: 'rol' },
      { name: 'Acción', itemKey: 'action', align: 'right' },
    ],
    items: users.map((user) => ({
      id: user.id,
      name: user.name,
      email: user.email,
      rol: user.type,
      action: Actions(user, setIsOpen, setIsOpenDelete, loginUser, dispatch),
    })),
  };
  return (
    <CustomTable
      color="primary"
      data={data}
      isPaginated
      paginationOptions={fetchOptions}
      onChange={handleOnChange}
    />
  );
}

export default UserTable;
