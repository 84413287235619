import { changeSearchValue, fetchUsers } from 'content/features/user/user.actions';
import {
  userSelector,
  searchValueSelector,
  isNewSelector,
  isLoadingSendSelector,
  currentPageSelector,
} from 'content/features/user/user.selector';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function useUser() {
  const dispatch = useDispatch();

  const users = useSelector(userSelector);
  const searchValue = useSelector(searchValueSelector);
  const isNew = useSelector(isNewSelector);
  const isLoadingSend = useSelector(isLoadingSendSelector);
  const currentPage = useSelector(currentPageSelector);

  const setSearchValue = (value) => {
    dispatch(changeSearchValue({ searchValue: value }));
  };

  const [idModal, setIdModal] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers({ searchValue, pageSize: 8, actualPage: currentPage || 1 }));
  }, [searchValue, isOpenDeleteModal, isOpenModal, isLoadingSend, currentPage]);

  return {
    users,
    setSearchValue,
    isOpenModal,
    setIsOpenModal,
    idModal,
    setIdModal,
    isNew,
    isOpenDeleteModal,
    setIsOpenDeleteModal,
  };
}

export default useUser;
