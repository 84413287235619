import apiRequest from 'utils/api';
import { put, spawn, takeLatest } from 'redux-saga/effects';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  fetchUsers,
  fetchUsersFail,
  fetchUsersSuccess,
  sendUser,
  sendUserFail,
  sendUserSuccess,
  updateUser,
} from './user.actions';

function* getUsers() {
  yield takeLatest(fetchUsers, function* getUsersToApi(action) {
    const { searchValue, pageSize, actualPage } = action.payload;
    const response = yield apiRequest(
      `api/v1/users?search=${searchValue}&take=${pageSize}&page=${actualPage}`,
      {
        method: 'GET',
      },
    );
    if (response) {
      yield put(
        fetchUsersSuccess({
          data: response.objects,
          totalPages: response.pagination.totalPages,
          actualPage: response.pagination.currentPage,
        }),
      );
    } else {
      yield put(fetchUsersFail({ message: response.message }));
    }
  });
}

function* createUser() {
  yield takeLatest(sendUser, function* sendUserToApi(action) {
    const { name, email, rol, haveDefaultPassword } = action.payload;
    const body = JSON.stringify({
      email,
      name,
      type: rol,
      isActive: true,
      haveDefaultPassword,
    });

    const response = yield apiRequest('api/v1/users', {
      method: 'POST',
      body,
    });
    if (response) {
      yield put(sendUserSuccess());
      defaultSuccessToast({ message: 'Usuario creado de forma exitosa' });
    } else {
      yield put(sendUserFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* patchUser() {
  yield takeLatest(updateUser, function* updateUserToApi(action) {
    const { name, email, rol, isActive, id, password, haveDefaultPassword, message } =
      action.payload;
    const body = JSON.stringify({
      email,
      name,
      password,
      type: rol,
      isActive,
      haveDefaultPassword,
    });

    const response = yield apiRequest(`api/v1/users/${id}`, {
      method: 'PATCH',
      body,
    });
    if (response) {
      defaultSuccessToast({ message: `${name} ${message} correctamente` });
      yield put(sendUserSuccess());
    } else {
      defaultErrorToast({ message: `Error al actualizar a ${name}` });
      yield put(sendUserFail());
    }
  });
}

export default function* UserSaga() {
  yield spawn(getUsers);
  yield spawn(createUser);
  yield spawn(patchUser);
}
