import React, { useEffect } from 'react';
/* Material UI */
import { Grid, Stack } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Project */
import CustomCard from 'content/features/other/components/CustomCard';
import SearchBar from 'content/features/other/components/SearchBar';
import PageTitle from 'content/features/other/components/PageTitle';
import {
  changeEndPeriodValue,
  changePagination,
  changeProcessValue,
  changeSearchValue,
  changeStartPeriodValue,
  fetchDiscounts,
  fetchScoreCardDiscounts,
  getReport,
} from 'content/features/summary-discounts/discounts.actions';
import { formatNumber } from 'utils/functions';
import CustomTable from 'content/features/other/components/CustomTable';
import {
  discountsDataSelector,
  currentPageSelector,
  totalPagesSelector,
  searchValueSelector,
  totalsSelector,
  startPeriodValueSelector,
  endPeriodValueSelector,
  processValueSelector,
  loadingButtonSelector,
} from 'content/features/summary-discounts/discounts.selector';

function SummaryDiscount() {
  const dispatch = useDispatch();
  const discountsData = useSelector(discountsDataSelector);
  const totalData = useSelector(totalsSelector);
  const currentPage = useSelector(currentPageSelector);
  const totalPage = useSelector(totalPagesSelector);
  const searchValue = useSelector(searchValueSelector);
  const startPeriodValue = useSelector(startPeriodValueSelector);
  const endPeriodValue = useSelector(endPeriodValueSelector);
  const processValue = useSelector(processValueSelector);
  const loadingButton = useSelector(loadingButtonSelector);

  const buttonOptions = {
    url: '/details-discount',
    isshown: true,
  };

  const fetchOptions = {
    totalPage,
    actualPage: currentPage || 1,
    pageSize: 10,
    searchValue,
    startPeriodValue,
    endPeriodValue,
    processValue,
  };

  useEffect(() => {
    dispatch(fetchDiscounts(fetchOptions));
    dispatch(fetchScoreCardDiscounts({ startPeriodValue, endPeriodValue, processValue }));
  }, [currentPage, searchValue, startPeriodValue, endPeriodValue, processValue]);

  function handleClick() {
    dispatch(getReport({ searchValue, startPeriodValue, endPeriodValue, processValue }));
  }

  const handleOnChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };

  const handleOnSearch = (value) => {
    dispatch(changeSearchValue({ searchValue: value }));
  };

  const handleOnStartPeriod = (value) => {
    dispatch(changeStartPeriodValue({ startPeriodValue: value }));
  };

  const handleOnEndPeriod = (value) => {
    dispatch(changeEndPeriodValue({ endPeriodValue: value }));
  };

  const handleOnProcess = (value) => {
    dispatch(changeProcessValue({ processValue: value }));
  };

  useEffect(
    () => () => {
      handleOnSearch('');
    },
    [],
  );

  const data = {
    headers: [
      { itemKey: 'code', name: 'Código elemento' },
      { itemKey: 'description', name: 'Descripción' },
      { itemKey: 'previousBalanceAmount', name: 'Monto acumulado anterior', align: 'right' },
      { itemKey: 'asignedMonthAmount', name: 'Monto asignado mes', align: 'right' },
      { itemKey: 'discountedAmount', name: 'Monto descontado', align: 'right' },
      { itemKey: 'actualBalanceAmount', name: 'Monto no descontado', align: 'right' },
    ],
    items: discountsData.map((item) => ({
      id: item.id,
      code: item.bukItemCode,
      description: item.name,
      previousBalanceAmount: `$ ${formatNumber(item.previousBalanceAmount)}`,
      asignedMonthAmount: `$ ${formatNumber(
        Math.abs(
          parseInt(item.discountedAmount, 10) +
            parseInt(item.actualBalanceAmount, 10) -
            parseInt(item.previousBalanceAmount, 10),
        ),
      )}`,
      discountedAmount: `$ ${formatNumber(item.discountedAmount)}`,
      actualBalanceAmount: `$ ${formatNumber(item.actualBalanceAmount)}`,
    })),
  };

  return (
    <>
      <PageTitle title="Descuentos totales" />
      <SearchBar
        placeholder="Código o descripción"
        onChangeSearch={handleOnSearch}
        onChangeStartPeriod={handleOnStartPeriod}
        onChangeEndPeriod={handleOnEndPeriod}
        onChangeProcess={handleOnProcess}
      />
      <Grid container justifyContent="space-between" pb={2}>
        <Stack direction="row" spacing={2}>
          <CustomCard
            title="Acumulado anterior"
            content={`$ ${formatNumber(totalData.previousBalanceAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Asignado del mes"
            content={`$ ${formatNumber(totalData.asignedMonthAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Descontado"
            content={`$ ${formatNumber(totalData.discountedAmount)}`}
            color="primary"
          />
          <CustomCard
            title="No descontado"
            content={`$ ${formatNumber(totalData.actualBalanceAmount)}`}
            color="primary"
          />
          <CustomCard
            title="Cuadratura"
            content={`$ ${formatNumber(
              totalData.previousBalanceAmount +
                totalData.asignedMonthAmount -
                totalData.discountedAmount -
                totalData.actualBalanceAmount,
            )}`}
            color="primary"
          />
        </Stack>
        <LoadingButton
          onClick={() => handleClick()}
          loading={loadingButton}
          variant="contained"
          size="large">
          Descarga reporte
        </LoadingButton>
      </Grid>
      <CustomTable
        color="primary"
        data={data}
        headersFontWeight="bold"
        buttonOptions={buttonOptions}
        isPaginated
        paginationOptions={fetchOptions}
        onChange={handleOnChange}
      />
    </>
  );
}

export default SummaryDiscount;
