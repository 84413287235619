export const employeeDataSelector = (state) => state.detailsPayroll.data;
export const loadingSelector = (state) => state.detailsPayroll.loaders.page;
export const updateLoadingSelector = (state) => state.detailsPayroll.loaders.update;
export const sendLoadingSelector = (state) => state.detailsPayroll.loaders.send;
export const successfulDeleteSelector = (state) => state.detailsPayroll.successfulDelete;

const DetailsPayrollSelector = {
  employeeData: employeeDataSelector,
  loading: loadingSelector,
  updateLoading: updateLoadingSelector,
  sendLoading: sendLoadingSelector,
  successfulDelete: successfulDeleteSelector,
};

export default DetailsPayrollSelector;
