import React from 'react';

import { Box, MenuItem, Select, Typography } from '@mui/material';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import SearchInput from './SearchInput';

function SearchBar({
  placeholder,
  onChangeSearch,
  onChangeStartPeriod,
  onChangeEndPeriod,
  onChangeProcess,
}) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box
        bgcolor="#F0F0F0"
        p={3}
        borderRadius={2}
        mb={3}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        spacing={2}>
        <SearchInput placeholder={placeholder} onChange={onChangeSearch} />
        <Typography fontWeight="bold">Período:</Typography>
        <DatePicker
          label="año, mes inicio"
          views={['month', 'year']}
          sx={{ backgroundColor: '#FFF' }}
          onChange={(date) => {
            onChangeStartPeriod(`01-${date.month() + 1}-${date.year()}`);
          }}
        />
        <DatePicker
          label="año, mes fin"
          views={['month', 'year']}
          sx={{ backgroundColor: '#FFF' }}
          onChange={(date) => {
            onChangeEndPeriod(`01-${date.month() + 1}-${date.year()}`);
          }}
        />
        <Typography fontWeight="bold">Proceso:</Typography>
        <Select
          defaultValue="0"
          sx={{ backgroundColor: '#FFF' }}
          onChange={(event) => onChangeProcess(event.target.value)}>
          <MenuItem value="0">Liquidación</MenuItem>
          <MenuItem value="1">Reliquidación</MenuItem>
        </Select>
      </Box>
    </LocalizationProvider>
  );
}

export default SearchBar;
