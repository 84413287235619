import React from 'react';
import PropTypes from 'prop-types';

/* Material UI */
import { Card, CardContent, Grid, TextField, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';

/* React Router */
// import { Link } from 'react-router-dom';

/* Project */
import { loginStyles } from 'content/features/authentication';
import PageTitle from 'content/features/other/components/PageTitle';

function LoginForm(props) {
  const { controls, errors, onChangeText, handleLogin, loading } = props;

  return (
    <form onSubmit={(evt) => evt.preventDefault()}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '88vh' }}>
        <Grid item md={4} lg={4} xs={12}>
          <Card elevation={2} style={{ margin: '0px 10px', boxShadow: 'none' }}>
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10}>
                  <PageTitle title="Inicia sesión" displayDivider="true" />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    autoFocus
                    inputProps={{ 'data-testid': 'text-field-email' }}
                    label="Correo electrónico"
                    id="email"
                    value={controls.email}
                    onChange={onChangeText}
                    style={{ paddingBottom: '8px' }}
                    fullWidth
                    error={!!errors?.email[0]?.message}
                    helperText={errors?.email[0]?.message}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    sx={loginStyles.textfield}
                    inputProps={{
                      'data-testid': 'text-field-password',
                      autocomplete: 'new-password',
                      form: {
                        autocomplete: 'off',
                      },
                    }}
                    label="Contraseña"
                    onChange={onChangeText}
                    value={controls.password}
                    id="password"
                    fullWidth
                    type="password"
                    error={!!errors?.password[0]?.message}
                    helperText={errors?.password[0]?.message}
                  />
                </Grid>
                {controls.error && (
                  <Grid item xs={10} textAlign="center">
                    <Typography style={{ color: 'red' }} align="center" variant="caption">
                      Credenciales inválidas
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={10} textAlign="center">
                  <LoadingButton
                    data-testid="button-login"
                    onClick={handleLogin}
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: '5px', width: '100%' }}
                    loadingPosition="start"
                    loading={loading}>
                    Iniciar sesión
                  </LoadingButton>
                </Grid>
                {/* <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Link to="/recovery" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button style={{ width: '90%' }} variant="outlined">
                        Recuperar contraseña
                      </Button>
                    </Link>
                  </Typography>
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <Typography variant="caption" color="primary">
                    <Link to="/register" style={{ textDecoration: 'none', width: '100%' }}>
                      <Button style={{ width: '90%' }} variant="outlined">
                        Registrarse
                      </Button>
                    </Link>
                  </Typography>
                </Grid> */}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

LoginForm.propTypes = {
  controls: PropTypes.shape({}).isRequired,
  errors: PropTypes.shape({}).isRequired,
  onChangeText: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

LoginForm.defaultProps = {
  loading: false,
};

export default LoginForm;
