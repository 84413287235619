export const userSelector = (state) => state.user.data;
export const searchValueSelector = (state) => state.user.searchValue;
export const isNewSelector = (state) => state.user.isNew;
export const currentPageSelector = (state) => state.user.currentPage;
export const totalPagesSelector = (state) => state.user.totalPages;
export const isLoadingSendSelector = (state) => state.user.isLoadingSend;
export const controlsSelector = (state) => state.user.controls;

const UserSelector = {
  usersData: userSelector,
  searchValue: searchValueSelector,
  isNew: isNewSelector,
  controls: controlsSelector,
  isLoadingSend: isLoadingSendSelector,
  currentPage: currentPageSelector,
  totalPages: totalPagesSelector,
};

export default UserSelector;
