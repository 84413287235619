import UserFeed from 'content/features/user/componentes/UserFeed';
import useUser from 'content/features/user/user.hooks';
import React from 'react';

function User() {
  const { users, isOpenModal, setIsOpenModal, isNew, isOpenDeleteModal, setIsOpenDeleteModal } =
    useUser();

  return (
    <UserFeed
      users={users}
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
      isNew={isNew}
      isOpenDeleteModal={isOpenDeleteModal}
      setIsOpenDeleteModal={setIsOpenDeleteModal}
    />
  );
}

export default User;
