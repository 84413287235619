import { takeLatest, put, spawn, call, takeEvery } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import {
  loadDataBase,
  loadDataBaseSuccess,
  loadDataBaseFail,
  lastPeriodSuccess,
  lastPeriodFail,
  lastPeriod,
  sendItems,
  sendItemsSuccess,
  sendItemsFail,
  lastUpdate,
  lastUpdateSuccess,
  lastUpdateFail,
  getClosedPayrollSuccess,
  getClosedPayrollFail,
  getClosedPayrollLoad,
  getClosedPayrollSend,
} from './bulkLoad.actions';

function* getPayroll() {
  // eslint-disable-next-line complexity
  yield takeLatest(loadDataBase, function* getPayrollToApi(action) {
    const { isReissued } = action.payload;
    const day = isReissued === '1' ? '28' : '01'; // TODO: eliminar al quitar fechas hardcode
    const response = yield apiRequest(
      `api/v1/bulkload/payroll?date=${day}-09-2023&isReissued=${isReissued}`,
      {
        // TODO: quitar fecha hardcode
        method: 'GET',
      },
    );
    if (response) {
      yield put(loadDataBaseSuccess());
      defaultSuccessToast({ message: 'Se inicia la carga de liquidaciones.' });
    } else {
      yield put(loadDataBaseFail());
      defaultErrorToast({ message: 'Falló la carga de liquidaciones' });
    }
  });
}

function* getJobsActive(name) {
  const response = yield apiRequest(`api/v1/bulkload/queue/jobs`, {
    method: 'GET',
  });
  if (response) {
    const cargaJob = response.find((job) => job.name === name);
    return cargaJob !== undefined;
  }
  return false;
}

function* getLastPeriodToApi() {
  const response = yield apiRequest(`api/v1/bulkload/period`, {
    method: 'GET',
  });
  if (!response.error) {
    yield put(lastPeriodSuccess({ objects: response.objects }));
  } else {
    yield put(lastPeriodFail());
  }
}

function* getLastPeriod() {
  yield takeLatest(lastPeriod, function* getPeriod() {
    const cargaJobActive = yield call(getJobsActive, 'load');
    if (!cargaJobActive) {
      yield call(getLastPeriodToApi);
    }
  });
}

function* updateItems() {
  yield takeLatest(sendItems, function* updateItemsToApi(action) {
    const { date } = action.payload;
    const response = yield apiRequest(`api/v1/bulkload/send-items?date=${date}`, {
      method: 'GET',
    });
    if (response) {
      yield put(sendItemsSuccess());
      defaultSuccessToast({ message: 'Se inició el envío de insolutos.' });
    } else {
      yield put(sendItemsFail());
      defaultErrorToast({ message: 'Falló la carga de liquidaciones' });
    }
  });
}

function* getLastUpdateToApi() {
  const response = yield apiRequest(`api/v1/bulkload/period-update`, {
    method: 'GET',
  });
  if (!response.error) {
    yield put(lastUpdateSuccess({ objects: response.objects }));
  } else {
    yield put(lastUpdateFail());
  }
}

function* getLastUpdate() {
  yield takeLatest(lastUpdate, function* getPeriod() {
    const cargaJobActive = yield call(getJobsActive, 'send');
    if (!cargaJobActive) {
      yield call(getLastUpdateToApi);
    }
  });
}

function generateParam(date, isReissued) {
  return date ? `?date=${date}&isReissued=${isReissued}` : '';
}

function* getClosedPayrolls() {
  yield takeEvery(
    [getClosedPayrollLoad, getClosedPayrollSend],
    function* getClosedPayrollsToApi(action) {
      const { date, isReissued, button } = action.payload;

      const dateParam = generateParam(date, isReissued);

      const response = yield apiRequest(`api/v1/bulkload/payroll/closed${dateParam}`, {
        method: 'GET',
      });
      if (!response.error) {
        yield put(
          getClosedPayrollSuccess({
            button,
            numbrePayrollsClosed: response.numbrePayrollsClosed,
            totalPayroll: response.totalPayroll,
            isReissued: response.isReissued,
            date: response.date,
          }),
        );
      } else {
        yield put(getClosedPayrollFail());
      }
    },
  );
}

export default function* BulkLoadSaga() {
  yield spawn(getPayroll);
  yield spawn(getLastPeriod);
  yield spawn(updateItems);
  yield spawn(getLastUpdate);
  yield spawn(getClosedPayrolls);
}
