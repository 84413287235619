import {
  changePagination,
  changeSearchValue,
  createItemType,
  createItemTypeFail,
  createItemTypeSuccess,
  fetchDiscountType,
  fetchDiscountTypeFail,
  fetchDiscountTypeSuccess,
  fetchItemType,
  fetchItemTypeFail,
  fetchItemTypeSuccess,
  resetControls,
  setControl,
  setControls,
  updateItemType,
  updateItemTypeFail,
  updateItemTypeSuccess,
} from './itemType.actions';

const { createReducer } = require('@reduxjs/toolkit');

const initialState = {
  pagination: {
    currentPage: 1,
    pageSize: 8,
    totalPage: 1,
  },
  discountType: [],
  searchValue: '',
  data: [],
  isLoadingFetch: false,
  errorFetch: null,
  isLoadingDiscount: false,
  errorDiscount: null,
  isLoadingCreate: false,
  errorCreate: null,
  isLoadingUpdate: false,
  errorUpdate: null,
  controls: {
    id: null,
    name: '',
    priority: 0,
    bukType: '',
    bukSubtype: '',
    bukCode: '',
    bukItemCode: '',
    discountType: 0,
    isOpen: true,
    type: 'descuento',
  },
};

const ItemTypeReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchItemType, (state) => {
      state.isLoadingFetch = true;
    })
    .addCase(fetchItemTypeSuccess, (state, action) => {
      state.isLoadingFetch = false;
      state.data = action.payload.data;
      state.pagination.totalPage = action.payload.totalPages;
    })
    .addCase(fetchItemTypeFail, (state, action) => {
      state.isLoadingFetch = false;
      state.errorFetch = action.payload.error;
    })
    .addCase(fetchDiscountType, (state) => {
      state.isLoadingDiscount = true;
    })
    .addCase(fetchDiscountTypeSuccess, (state, action) => {
      state.isLoadingDiscount = false;
      state.discountType = action.payload.data;
    })
    .addCase(fetchDiscountTypeFail, (state, action) => {
      state.isLoadingDiscount = false;
      state.errorDiscount = action.payload.error;
    })
    .addCase(createItemType, (state) => {
      state.isLoadingCreate = true;
    })
    .addCase(createItemTypeSuccess, (state) => {
      state.isLoadingCreate = false;
    })
    .addCase(createItemTypeFail, (state, action) => {
      state.isLoadingCreate = false;
      state.errorCreate = action.payload.error;
    })
    .addCase(updateItemType, (state) => {
      state.isLoadingUpdate = true;
    })
    .addCase(updateItemTypeSuccess, (state) => {
      state.isLoadingUpdate = false;
    })
    .addCase(updateItemTypeFail, (state, action) => {
      state.isLoadingUpdate = false;
      state.errorUpdate = action.payload.error;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.searchValue = action.payload.searchValue;
      state.pagination.currentPage = initialState.pagination.currentPage;
    })
    .addCase(changePagination, (state, action) => {
      state.pagination.currentPage = action.payload.currentPage;
    })
    .addCase(setControl, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(setControls, (state, { payload }) => {
      state.controls = { ...state.controls, ...payload };
    })
    .addCase(resetControls, (state) => {
      state.controls = { ...initialState.controls };
    });
});

export default ItemTypeReducer;
