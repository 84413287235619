import { takeLatest, spawn, put } from 'redux-saga/effects';

/* Project */
import apiRequest from 'utils/api';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchDiscount,
  fetchDiscountSuccess,
  fetchDiscountFail,
  fetchDiscountEmployees,
  fetchDiscountEmployeesFail,
  fetchDiscountEmployeesSuccess,
  fetchDiscountScoreCard,
  fetchDiscountScoreCardSuccess,
  fetchDiscountScoreCardFail,
} from './discountEmployee.action';

function* fetchDiscountItemType() {
  yield takeLatest(fetchDiscount, function* fetchDiscountToApi(action) {
    const { id } = action.payload;
    const response = yield apiRequest(`api/v1/item/${id}`, { method: 'get' });
    if (response) {
      yield put(fetchDiscountSuccess({ objects: response.objects }));
    } else {
      yield put(fetchDiscountFail());
      defaultErrorToast({ message: 'Falló la carga del descuento.' });
    }
  });
}

function* fetchDiscountEmployee() {
  yield takeLatest(fetchDiscountEmployees, function* fetchDiscountEmployeeToApi(action) {
    const {
      id,
      pageSize,
      actualPage,
      searchValue,
      startPeriodValue,
      endPeriodValue,
      processValue,
    } = action.payload;
    const response = yield apiRequest(
      `api/v1/item/${id}/employee?start_date=${startPeriodValue}&end_date=${endPeriodValue}&take=${pageSize}&page=${actualPage}&search=${searchValue}&isReissued=${processValue}`,
      { method: 'get' },
    );
    if (response) {
      yield put(
        fetchDiscountEmployeesSuccess({
          objects: response.objects,
          totalPages: response.pagination.totalPages,
          currentPage: response.pagination.currentPage,
        }),
      );
    } else {
      yield put(fetchDiscountEmployeesFail());
      defaultErrorToast({ message: 'Falló la carga del descuento.' });
    }
  });
}

function* fetchDiscountTotal() {
  yield takeLatest(fetchDiscountScoreCard, function* fetchDiscountTotalToApi(action) {
    const { id, startPeriodValue, endPeriodValue, processValue } = action.payload;
    const response = yield apiRequest(
      `api/v1/item/${id}/total/?start_date=${startPeriodValue}&end_date=${endPeriodValue}&isReissued=${processValue}`,
      {
        method: 'get',
      },
    );
    if (response) {
      yield put(
        fetchDiscountScoreCardSuccess({
          previousBalanceAmount: response.previousBalanceAmount,
          asignedMonthAmount: response.asignedMonthAmount,
          discountedAmount: response.discountedAmount,
          actualBalanceAmount: response.actualBalanceAmount,
        }),
      );
    } else {
      yield put(fetchDiscountScoreCardFail());
      defaultErrorToast({ message: 'Falló la carga del descuento.' });
    }
  });
}

export default function* DiscountEmployeeSaga() {
  yield spawn(fetchDiscountItemType);
  yield spawn(fetchDiscountEmployee);
  yield spawn(fetchDiscountTotal);
}
