import React from 'react';

import { Box, Grid, IconButton, InputBase, Select, Typography, MenuItem } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment/moment';

function SearchPayroll({
  placeholder,
  onChangeSearch,
  onChangeDatePicker,
  onChangeProcess,
  onChangeStatus,
  values,
}) {
  const { searchValue, dateValue, processValue, statusValue } = values;

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Box bgcolor="#F0F0F0" p={3} borderRadius={2} mb={3}>
        <Grid container alignItems="center">
          <Grid item md={3}>
            <Box bgcolor="#FFF" border={1} borderColor="#c1c1c1" borderRadius={2} pl={2} pr={3}>
              <Grid container justifyContent="space-between" alignItems="center">
                <Grid item md={11}>
                  <InputBase
                    sx={{ width: '96%' }}
                    placeholder={placeholder}
                    value={searchValue}
                    onChange={(event) => {
                      onChangeSearch(event.target.value);
                    }}
                  />
                </Grid>
                <Grid item md={1} textAlign="right">
                  <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                    <SearchIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item container alignItems="center" md={3} pl={2}>
            <Grid item md={4}>
              <Typography fontWeight="bold">Periodo:</Typography>
            </Grid>
            <Grid item md={8}>
              <DatePicker
                label="mes, año"
                format="MM/YYYY"
                views={['month', 'year']}
                sx={{ backgroundColor: '#FFF' }}
                value={moment(dateValue, ['DD-MM-YYYY', 'YYYY-MM-DD']).utc()}
                onChange={(date) => {
                  if (date?.isValid()) {
                    onChangeDatePicker(`01-${date.month() + 1}-${date.year()}`);
                  }
                }}
              />
            </Grid>
          </Grid>
          <Grid item container alignItems="center" md={3} pl={2}>
            <Grid item md={4}>
              <Typography fontWeight="bold">Proceso:</Typography>
            </Grid>
            <Grid item md={8}>
              <Select
                defaultValue="0"
                sx={{ backgroundColor: '#FFF' }}
                value={processValue}
                onChange={(event) => onChangeProcess(event.target.value)}>
                <MenuItem value="0">Liquidación</MenuItem>
                <MenuItem value="1">Reliquidación</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid item container alignItems="center" md={3} pl={2}>
            <Grid item md={4}>
              <Typography fontWeight="bold">Estado:</Typography>
            </Grid>
            <Grid item md={8}>
              <Select
                defaultValue="1"
                sx={{ backgroundColor: '#FFF' }}
                value={statusValue}
                onChange={(event) => onChangeStatus(event.target.value)}>
                <MenuItem value="0">Regularizado</MenuItem>
                <MenuItem value="1">No regularizado</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </LocalizationProvider>
  );
}

export default SearchPayroll;
