import React, { useEffect } from 'react';
import { Typography, Grid } from '@mui/material';
import CustomTable from 'content/features/other/components/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeDateValue,
  changeLocationKey,
  changePagination,
  changeProcessValue,
  changeSearchValue,
  changeStatusValue,
  fetchPayroll,
  fetchPayrollCards,
  setResetState,
} from 'content/features/list-payroll/list-payroll.actions';
import {
  currentPageSelector,
  dateValueSelector,
  locationKeySelector,
  payrollDataCardsSelector,
  payrollDataSelector,
  processValueSelector,
  searchValueSelector,
  statusValueSelector,
  totalPagesSelector,
} from 'content/features/list-payroll/list-payroll.selector';
import { formatNumber, formatRut } from 'utils/functions';
import CustomCard from 'content/features/other/components/CustomCard';
import SearchPayroll from 'content/features/other/components/SearchPayroll';
import { useLocation } from 'react-router-dom';

function ListPayroll() {
  const location = useLocation();
  const dispatch = useDispatch();
  const payrollData = useSelector(payrollDataSelector);
  const currentPage = useSelector(currentPageSelector);
  const totalPage = useSelector(totalPagesSelector);
  const searchValue = useSelector(searchValueSelector);
  const dateValue = useSelector(dateValueSelector);
  const processValue = useSelector(processValueSelector);
  const statusValue = useSelector(statusValueSelector);
  const cardsData = useSelector(payrollDataCardsSelector);
  const locationKey = useSelector(locationKeySelector);
  const buttonOptions = {
    url: '/details-payroll',
    isshown: true,
  };
  const fetchOptions = {
    totalPage,
    actualPage: currentPage || 1,
    pageSize: 10,
    searchValue,
    dateValue,
    processValue,
    statusValue,
  };
  const data = {
    headers: [
      { name: 'Nombre', itemKey: 'name' },
      { name: 'RUT', itemKey: 'rut' },
      { name: 'Insoluto actual', itemKey: 'amount', align: 'right' },
    ],
    items: payrollData.map((item) => ({
      id: item.payrolls[0].id,
      name: item.bukName,
      rut: formatRut(item.bukRut),
      amount:
        `$ ${formatNumber(
          item.payrolls[0]?.itemsBalances?.reduce(
            (acc, curr) => acc + parseInt(curr.amount, 10),
            0,
          ),
        )}` || '$ 0',
    })),
  };
  const handleOnChange = (value) => {
    dispatch(changePagination({ currentPage: value }));
  };

  const handleOnSearch = (value) => {
    dispatch(changeSearchValue({ searchValue: value }));
  };

  const handleOnChangeDatePicker = (value) => {
    dispatch(changeDateValue({ dateValue: value }));
  };

  const handleOnChangeProcess = (value) => {
    dispatch(changeProcessValue({ processValue: value }));
  };

  const handleOnChangeStatus = (value) => {
    dispatch(changeStatusValue({ statusValue: value }));
  };

  useEffect(() => {
    if (location.key !== locationKey) {
      dispatch(setResetState());
      dispatch(changeLocationKey({ locationKey: location.key }));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchPayroll(fetchOptions));
  }, [currentPage, searchValue, dateValue, processValue, statusValue]);

  useEffect(() => {
    dispatch(fetchPayrollCards(fetchOptions));
  }, [dateValue, processValue, statusValue]);

  const previousBalance = formatNumber(cardsData?.previousBalanceAmount);
  const actualBalance = formatNumber(cardsData?.actualBalanceAmount);
  const asignedMonth = formatNumber(cardsData?.payrollDiscountsAmount);
  const discounted = formatNumber(cardsData?.DiscountedAmount);
  return (
    <Grid container justifyContent="center" alignItems="center" paddingTop={3}>
      <Grid item md={12} xs={12}>
        <Typography variant="h3" fontWeight="bold" sx={{ paddingBottom: '48px' }}>
          Listado de liquidaciones
        </Typography>
        <SearchPayroll
          placeholder="Nombre o RUT"
          onChangeSearch={handleOnSearch}
          onChangeDatePicker={handleOnChangeDatePicker}
          onChangeProcess={handleOnChangeProcess}
          onChangeStatus={handleOnChangeStatus}
          values={{ searchValue, dateValue, processValue, statusValue }}
        />
        <Grid container justifyContent="space-between">
          <Grid item>
            <CustomCard
              title="Acumulado anterior"
              content={`$ ${previousBalance}`}
              color="primary"
            />
          </Grid>
          <Grid item>
            <CustomCard title="Asignado del mes" content={`$ ${asignedMonth}`} color="primary" />
          </Grid>
          <Grid item>
            <CustomCard title="Descontado" content={`$ ${discounted}`} color="primary" />
          </Grid>
          <Grid item>
            <CustomCard title="No descontado" content={`$ ${actualBalance}`} color="primary" />
          </Grid>
        </Grid>
        <CustomTable
          color="primary"
          data={data}
          buttonOptions={buttonOptions}
          isPaginated
          paginationOptions={fetchOptions}
          onChange={handleOnChange}
        />
      </Grid>
    </Grid>
  );
}

export default ListPayroll;
