import { put, spawn, takeLatest } from 'redux-saga/effects';
import apiRequest from 'utils/api';
import defaultSuccessToast from 'utils/toastify/defaultSuccessToast';
import defaultErrorToast from 'utils/toastify/defaultErrorToast';
import {
  fetchEmployee,
  fetchEmployeeSuccess,
  fetchEmployeeFail,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFail,
  sendEmployeePayroll,
  sendEmployeePayrollSuccess,
  sendEmployeePayrollFail,
  deleteItemBalance,
  deleteItemBalanceSuccess,
  deleteItemBalanceFail,
} from './details-payroll.actions';

function* getEmployee() {
  yield takeLatest(fetchEmployee, function* getEmployeeToApi(action) {
    const { idPayroll } = action.payload;
    const response = yield apiRequest(`api/v1/employee/${idPayroll}?date=01-09-2023`, {
      // TODO: quitar fecha hardcode TODAY
      method: 'GET',
    });
    if (response) {
      yield put(
        fetchEmployeeSuccess({
          objects: response.objects,
        }),
      );
    } else {
      yield put(fetchEmployeeFail());
    }
  });
}

function* updateEmployeePayroll() {
  yield takeLatest(updateEmployee, function* updateEmployeePayrollToApi(action) {
    const { idPayroll } = action.payload;
    const response = yield apiRequest(`api/v1/employee/update-payroll/${idPayroll}`, {
      method: 'GET',
    });
    if (!response.error) {
      yield put(updateEmployeeSuccess());
      defaultSuccessToast({ message: 'liquidación actualizada con exito' });
    } else {
      yield put(updateEmployeeFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* sendPayroll() {
  yield takeLatest(sendEmployeePayroll, function* sendEmployeePayrolltoApi(action) {
    const { idPayroll } = action.payload;
    const response = yield apiRequest(`api/v1/employee/send-payroll/${idPayroll}`, {
      method: 'POST',
    });
    if (!response.error) {
      yield put(sendEmployeePayrollSuccess());
      defaultSuccessToast({ message: 'Liquidación enviada con éxito' });
    } else {
      yield put(sendEmployeePayrollFail());
      defaultErrorToast({ message: response.message });
    }
  });
}

function* deleteItem() {
  yield takeLatest(deleteItemBalance, function* deleteItemBalancetoApi(action) {
    const { idPayroll, idItemBalance } = action.payload;
    const body = JSON.stringify({
      id: idItemBalance,
    });
    const response = yield apiRequest(`api/v1/employee/${idPayroll}`, {
      method: 'PATCH',
      body,
    });
    if (!response.error) {
      yield put(deleteItemBalanceSuccess());
      defaultSuccessToast({ message: 'Descuento acumulado eliminado con exito' });
    } else {
      yield put(deleteItemBalanceFail());
      defaultErrorToast({
        message: 'Ocurrio un problema al intentar eliminar el descuento acumulado',
      });
    }
  });
}

export default function* EmployeeSaga() {
  yield spawn(getEmployee);
  yield spawn(updateEmployeePayroll);
  yield spawn(sendPayroll);
  yield spawn(deleteItem);
}
