import { createReducer } from '@reduxjs/toolkit';
import {
  fetchEmployee,
  fetchEmployeeSuccess,
  fetchEmployeeFail,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFail,
  clearSelectedEmployee,
  sendEmployeePayroll,
  sendEmployeePayrollSuccess,
  sendEmployeePayrollFail,
  deleteItemBalance,
  deleteItemBalanceSuccess,
  deleteItemBalanceFail,
} from './details-payroll.actions';

const initialState = {
  loaders: {
    page: false,
    update: false,
    send: false,
  },
  data: {
    employee: {},
    payroll: {},
    itemBalance: [],
  },
  successfulDelete: false,
};
const ListPayrollReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchEmployee, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchEmployeeSuccess, (state, action) => {
      state.loaders.page = false;
      state.data = action.payload.objects;
    })
    .addCase(fetchEmployeeFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(updateEmployee, (state) => {
      state.loaders.update = true;
    })
    .addCase(updateEmployeeSuccess, (state) => {
      state.loaders.update = false;
    })
    .addCase(updateEmployeeFail, (state) => {
      state.loaders.update = false;
    })
    .addCase(sendEmployeePayroll, (state) => {
      state.loaders.send = true;
    })
    .addCase(sendEmployeePayrollSuccess, (state) => {
      state.loaders.send = false;
    })
    .addCase(sendEmployeePayrollFail, (state) => {
      state.loaders.send = false;
    })
    .addCase(clearSelectedEmployee, (state) => {
      state.data.employee = {};
      state.data.payroll = {};
      state.data.itemBalance = [];
      state.successfulDelete = false;
    })
    .addCase(deleteItemBalance, (state) => {
      state.loaders.page = true;
    })
    .addCase(deleteItemBalanceSuccess, (state) => {
      state.successfulDelete = true;
      state.loaders.page = false;
    })
    .addCase(deleteItemBalanceFail, (state) => {
      state.loaders.page = false;
    });
});

export default ListPayrollReducer;
