import { createAction } from '@reduxjs/toolkit';

import ChangePasswordTypes from './changePassword.types';

export const setControls = createAction(ChangePasswordTypes.SET_CONTROLS);

export const setControl = createAction(ChangePasswordTypes.SET_CONTROL, ({ name, value }) => ({
  payload: { [name]: value },
}));

export const resetControls = createAction(ChangePasswordTypes.RESET_CONTROLS);

export const updatePassword = createAction(
  ChangePasswordTypes.UPDATE_PASSWORD,
  ({ password, user, navigate }) => ({
    payload: { password, user, navigate },
  }),
);

export const updatePasswordSuccess = createAction(ChangePasswordTypes.UPDATE_PASSWORD_SUCCESS);

export const updatePasswordFail = createAction(ChangePasswordTypes.UPDATE_PASSWORD_FAIL);

const ChangePasswordActions = {
  setControl,
  setControls,
  resetControls,
  updatePassword,
  updatePasswordSuccess,
  updatePasswordFail,
};

export default ChangePasswordActions;
