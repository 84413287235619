import React from 'react';

/* Project */
import PageTitle from 'content/features/other/components/PageTitle';
import { ExtractionBox, SendBox } from 'content/features/bull-load';

function BulkLoad() {
  return (
    <>
      <PageTitle title="Carga masiva" displayDivider="true" />
      <ExtractionBox />
      <SendBox />
    </>
  );
}

export default BulkLoad;
