import { setControl } from 'content/features/changePassword/changePassword.actions';
import {
  controlsSelector,
  isLoadingSelector,
} from 'content/features/changePassword/changePassword.selector';
import ChangePasswordForm from 'content/features/changePassword/components/ChangePasswordForm';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function ChangePassword() {
  const dispatch = useDispatch();

  const controls = useSelector(controlsSelector);
  const loading = useSelector(isLoadingSelector);

  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);

  const content = (
    <ChangePasswordForm controls={controls} onChangeText={onChangeText} loading={loading} />
  );

  return content;
}

export default ChangePassword;
