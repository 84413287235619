export const payrollDataSelector = (state) => state.listPayroll.data;
export const payrollDataCardsSelector = (state) => state.listPayroll.dataCards;
export const currentPageSelector = (state) => state.listPayroll.currentPage;
export const totalPagesSelector = (state) => state.listPayroll.totalPages;
export const searchValueSelector = (state) => state.listPayroll.searchValue;
export const dateValueSelector = (state) => state.listPayroll.dateValue;
export const processValueSelector = (state) => state.listPayroll.processValue;
export const statusValueSelector = (state) => state.listPayroll.statusValue;
export const locationKeySelector = (state) => state.listPayroll.locationKey;

const ListPayrollSelector = {
  payrollData: payrollDataSelector,
  payrollDataCards: payrollDataCardsSelector,
  currentPage: currentPageSelector,
  totalPages: totalPagesSelector,
  searchValue: searchValueSelector,
  dateValue: dateValueSelector,
  processValue: processValueSelector,
  statusValue: statusValueSelector,
  locationKey: locationKeySelector,
};

export default ListPayrollSelector;
