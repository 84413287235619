const BASE = '[DiscountEmployeeTypes]';

const DiscountEmployeeTypes = {
  FETCH_DISCOUNT: `${BASE} FETCH_DISCOUNT`,
  FETCH_DISCOUNT_SUCCESS: `${BASE} FETCH_DISCOUNT_SUCCESS`,
  FETCH_DISCOUNT_FAIL: `${BASE} FETCH_DISCOUNT_FAIL`,
  FETCH_DISCOUNT_EMPLOYEES: `${BASE} FETCH_DISCOUNT_EMPLOYEES`,
  FETCH_DISCOUNT_EMPLOYEES_SUCCESS: `${BASE} FETCH_DISCOUNT_EMPLOYEES_SUCCESS`,
  FETCH_DISCOUNT_EMPLOYEES_FAIL: `${BASE} FETCH_DISCOUNT_EMPLOYEES_FAIL`,
  FETCH_DISCOUNT_SCORE_CART: `${BASE} FETCH_DISCOUNT_SCORE_CART`,
  FETCH_DISCOUNT_SCORE_CART_SUCCESS: `${BASE} FETCH_DISCOUNT_SCORE_CART_SUCCESS`,
  FETCH_DISCOUNT_SCORE_CART_FAIL: `${BASE} FETCH_DISCOUNT_SCORE_CART_FAIL`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  CHANGE_SEARCH_VALUE: `${BASE} CHANGE_SEARCH_VALUE`,
  CHANGE_START_PERIOD: `${BASE} CHANGE_START_PERIOD`,
  CHANGE_END_PERIOD: `${BASE} CHANGE_END_PERIOD`,
  CHANGE_PROCESS: `${BASE} CHANGE_PROCESS`,
};

export default DiscountEmployeeTypes;
