import { createAction } from '@reduxjs/toolkit';
import DiscountEmployeeTypes from './discountEmployee.types';

export const fetchDiscount = createAction(DiscountEmployeeTypes.FETCH_DISCOUNT);

export const fetchDiscountSuccess = createAction(
  DiscountEmployeeTypes.FETCH_DISCOUNT_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchDiscountFail = createAction(DiscountEmployeeTypes.FETCH_DISCOUNT_FAIL);

export const fetchDiscountEmployees = createAction(DiscountEmployeeTypes.FETCH_DISCOUNT_EMPLOYEES);

export const fetchDiscountEmployeesSuccess = createAction(
  DiscountEmployeeTypes.FETCH_DISCOUNT_EMPLOYEES_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchDiscountEmployeesFail = createAction(
  DiscountEmployeeTypes.FETCH_DISCOUNT_EMPLOYEES_FAIL,
);

export const fetchDiscountScoreCard = createAction(DiscountEmployeeTypes.FETCH_DISCOUNT_SCORE_CART);

export const fetchDiscountScoreCardSuccess = createAction(
  DiscountEmployeeTypes.FETCH_DISCOUNT_SCORE_CART_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
    },
  }),
);

export const fetchDiscountScoreCardFail = createAction(
  DiscountEmployeeTypes.FETCH_DISCOUNT_SCORE_CART_FAIL,
);

export const changePagination = createAction(
  DiscountEmployeeTypes.CHANGE_PAGINATION,
  (payload) => ({
    payload: {
      ...payload,
      currentPage: payload.currentPage,
    },
  }),
);

export const changeSearchValue = createAction(
  DiscountEmployeeTypes.CHANGE_SEARCH_VALUE,
  (payload) => ({
    payload: {
      ...payload,
      searchValue: payload.searchValue,
    },
  }),
);

export const changeStartPeriodValue = createAction(
  DiscountEmployeeTypes.CHANGE_START_PERIOD,
  (payload) => ({
    payload: {
      ...payload,
      startPeriodValue: payload.startPeriodValue,
    },
  }),
);

export const changeEndPeriodValue = createAction(
  DiscountEmployeeTypes.CHANGE_END_PERIOD,
  (payload) => ({
    payload: {
      ...payload,
      endPeriodValue: payload.endPeriodValue,
    },
  }),
);

export const changeProcessValue = createAction(DiscountEmployeeTypes.CHANGE_PROCESS, (payload) => ({
  payload: {
    ...payload,
    processValue: payload.processValue,
  },
}));

const DiscountEmployeeActions = {
  fetchDiscount,
  fetchDiscountSuccess,
  fetchDiscountFail,
  fetchDiscountEmployees,
  fetchDiscountEmployeesSuccess,
  fetchDiscountEmployeesFail,
  changePagination,
  changeSearchValue,
  changeStartPeriodValue,
  changeEndPeriodValue,
  changeProcessValue,
};

export default DiscountEmployeeActions;
