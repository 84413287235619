import { createAction } from '@reduxjs/toolkit';
import DetailsPayrollTypes from './details-payroll.types';

export const fetchEmployee = createAction(DetailsPayrollTypes.FETCH_EMPLOYEE);

export const fetchEmployeeSuccess = createAction(
  DetailsPayrollTypes.FETCH_EMPLOYEE_SUCCESS,
  (payload) => ({
    payload: {
      ...payload,
      objects: payload.objects,
    },
  }),
);

export const fetchEmployeeFail = createAction(DetailsPayrollTypes.FETCH_EMPLOYEE_FAIL);

export const updateEmployee = createAction(DetailsPayrollTypes.UPDATE_EMPLOYEE_PAYROLL);

export const updateEmployeeSuccess = createAction(
  DetailsPayrollTypes.UPDATE_EMPLOYEE_PAYROLL_SUCCESS,
);

export const updateEmployeeFail = createAction(DetailsPayrollTypes.UPDATE_EMPLOYEE_PAYROLL_FAIL);

export const sendEmployeePayroll = createAction(DetailsPayrollTypes.SEND_EMPLOYEE_PAYROLL);

export const sendEmployeePayrollSuccess = createAction(
  DetailsPayrollTypes.SEND_EMPLOYEE_PAYROLL_SUCCESS,
);

export const sendEmployeePayrollFail = createAction(DetailsPayrollTypes.SEND_EMPLOYEE_PAYROLL_FAIL);

export const clearSelectedEmployee = createAction(DetailsPayrollTypes.CLEAR_SELECTED_EMPLOYEE);

export const deleteItemBalance = createAction(DetailsPayrollTypes.DELETE_ITEM_BALANCE);

export const deleteItemBalanceSuccess = createAction(
  DetailsPayrollTypes.DELETE_ITEM_BALANCE_SUCCESS,
);

export const deleteItemBalanceFail = createAction(DetailsPayrollTypes.DELETE_ITEM_BALANCE_FAIL);

const DetailsPayrollActions = {
  fetchEmployee,
  fetchEmployeeSuccess,
  fetchEmployeeFail,
  updateEmployee,
  updateEmployeeSuccess,
  updateEmployeeFail,
  sendEmployeePayroll,
  sendEmployeePayrollSuccess,
  sendEmployeePayrollFail,
  clearSelectedEmployee,
};

export default DetailsPayrollActions;
