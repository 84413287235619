import { createReducer } from '@reduxjs/toolkit';

import {
  fetchDiscount,
  fetchDiscountSuccess,
  fetchDiscountFail,
  fetchDiscountEmployees,
  fetchDiscountEmployeesSuccess,
  fetchDiscountEmployeesFail,
  fetchDiscountScoreCard,
  fetchDiscountScoreCardSuccess,
  fetchDiscountScoreCardFail,
  changeSearchValue,
  changeStartPeriodValue,
  changeEndPeriodValue,
  changeProcessValue,
} from './discountEmployee.action';

const initialState = {
  loaders: {
    page: false,
  },
  data: {
    item: {},
    employees: [],
    scordCard: {
      previousBalanceAmount: 0,
      asignedMonthAmount: 0,
      discountedAmount: 0,
      actualBalanceAmount: 0,
    },
  },
  currentPage: 1,
  totalPages: 0,
  searchValue: '',
  startPeriodValue: '01-09-2023', // TODO: PRIMER DIA DEL MES ACTUAL
  endPeriodValue: '01-09-2023', // TODO: ULTIMO DIA DEL MES ACTUAL
  processValue: '0',
};

const DiscountReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchDiscount, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchDiscountSuccess, (state, action) => {
      state.loaders.page = false;
      state.data.item = action.payload.objects;
    })
    .addCase(fetchDiscountFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchDiscountEmployees, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchDiscountEmployeesSuccess, (state, action) => {
      state.loaders.page = false;
      state.data.employees = action.payload.objects;
      state.totalPages = action.payload.totalPages;
    })
    .addCase(fetchDiscountEmployeesFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(fetchDiscountScoreCard, (state) => {
      state.loaders.page = true;
    })
    .addCase(fetchDiscountScoreCardSuccess, (state, action) => {
      state.loaders.page = false;
      state.data.scordCard.previousBalanceAmount = action.payload.previousBalanceAmount;
      state.data.scordCard.asignedMonthAmount = action.payload.asignedMonthAmount;
      state.data.scordCard.discountedAmount = action.payload.discountedAmount;
      state.data.scordCard.actualBalanceAmount = action.payload.actualBalanceAmount;
    })
    .addCase(fetchDiscountScoreCardFail, (state) => {
      state.loaders.page = false;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.searchValue = action.payload.searchValue;
      state.currentPage = 1;
    })
    .addCase(changeStartPeriodValue, (state, action) => {
      state.startPeriodValue = action.payload.startPeriodValue;
      state.currentPage = 1;
    })
    .addCase(changeEndPeriodValue, (state, action) => {
      state.endPeriodValue = action.payload.endPeriodValue;
      state.currentPage = 1;
    })
    .addCase(changeProcessValue, (state, action) => {
      state.processValue = action.payload.processValue;
      state.currentPage = 1;
    });
});

export default DiscountReducer;
