/* Reducers */
import { CounterReducer } from 'content/features/counter';
import AppReducer from 'content/app.reducer';
import { LoginReducer, RecoveryReducer, RegisterReducer } from 'content/features/authentication';
import { UserTasksReducer } from 'content/features/tasks';
import { BulkLoadReducer } from 'content/features/bull-load';
import { ListPayrollReducer } from 'content/features/list-payroll';
import { DetailsPayrollReducer } from 'content/features/details-payroll';
import { DiscountEmployeeReducer } from 'content/features/details-discounts';
import { DiscountsReducer } from 'content/features/summary-discounts';
import { UserReducer } from 'content/features/user';
import { ChangePasswordReducer } from 'content/features/changePassword';
import { ItemTypeReducer } from 'content/features/item-type';

const MainReducer = {
  app: AppReducer,
  login: LoginReducer,
  register: RegisterReducer,
  counter: CounterReducer,
  recovery: RecoveryReducer,
  userTasks: UserTasksReducer,
  bulkLoad: BulkLoadReducer,
  listPayroll: ListPayrollReducer,
  detailsPayroll: DetailsPayrollReducer,
  discounts: DiscountsReducer,
  discountEmployee: DiscountEmployeeReducer,
  user: UserReducer,
  changePassword: ChangePasswordReducer,
  itemType: ItemTypeReducer,
};

export default MainReducer;
