import { Button, Grid, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import CustomDivider from 'content/features/other/components/CustomDivider';
import CloseIcon from '@mui/icons-material/Close';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useForm from 'hooks/useForm';
import { validateEmail } from 'utils/functions';
import { changeIsNew, resetControls, sendUser, setControl, updateUser } from '../user.actions';
import { controlsSelector } from '../user.selector';

function UserForm({ setIsOpen, isNew }) {
  const dispatch = useDispatch();
  const controls = useSelector(controlsSelector);
  const onChangeText = useCallback((name, value) => {
    dispatch(setControl({ name, value }));
  }, []);
  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validateOnChange: true,
    validations: {
      name: [
        {
          check: (value) => !!value,
          message: 'El nombre es requerido',
        },
      ],
      email: [
        {
          check: (value) => !!value,
          message: 'El email es requerido',
        },
        {
          check: (value) => validateEmail(value).check,
          message: 'El correo electrónico debe ser válido',
        },
      ],
      rol: [
        {
          check: (value) => !!value,
          message: 'El rol es requerido',
        },
      ],
    },
  });

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 846,
    bgcolor: 'background.paper',
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    p: 4,
  };

  const handleIsNew = () => {
    const handleClickReset = () => {
      dispatch(
        updateUser({
          id: controls.id,
          name: controls.name,
          email: controls.email,
          rol: controls.rol,
          isActive: true,
          haveDefaultPassword: true,
          message: 'restablecido',
        }),
      );
      setIsOpen(false);
    };
    let title;
    let buttons;
    let handleSubmit;
    if (isNew) {
      title = 'Crear usuario';
      handleSubmit = () => {
        dispatch(
          sendUser({
            name: controls.name,
            email: controls.email,
            rol: controls.rol,
            haveDefaultPassword: true,
          }),
        );
        dispatch(resetControls());
        dispatch(changeIsNew({ isNew: false }));
      };
      buttons = (
        <Grid item md={12}>
          <Button variant="contained" color="secondary" fullWidth type="submit">
            Crear usuario
          </Button>
        </Grid>
      );
    } else {
      title = 'Editar usuario';
      handleSubmit = () => {
        dispatch(
          updateUser({
            id: controls.id,
            name: controls.name,
            email: controls.email,
            rol: controls.rol,
            isActive: true,
            message: 'actualizado',
          }),
        );
        dispatch(resetControls());
        dispatch(changeIsNew({ isNew: false }));
      };

      buttons = (
        <>
          <Grid item md={6}>
            <Button variant="contained" color="primary" onClick={handleClickReset} fullWidth>
              Restablecer contraseña
            </Button>
          </Grid>
          <Grid item md={6}>
            <Button variant="contained" color="secondary" type="submit" fullWidth>
              Guardar
            </Button>
          </Grid>
        </>
      );
    }
    return { title, buttons, handleSubmit };
  };

  const onClickSendUser = (event) => {
    event.preventDefault();
    onSubmit(handleIsNew(isNew).handleSubmit);
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
    dispatch(changeIsNew({ isNew: false }));
  };

  return (
    <form onSubmit={onClickSendUser}>
      <Grid container sx={style} justifyContent="center">
        <Grid item container md={12} justifyContent="flex-end">
          <Grid item>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item container md={8}>
          <Grid item md={11}>
            <Typography variant="h4" fontWeight="bold">
              {handleIsNew().title}
            </Typography>
          </Grid>
          <Grid item md={1} />
          <CustomDivider color="secondary" type="title" />
        </Grid>
        <Grid item container md={8}>
          <Grid item md={12} pb={2}>
            <Typography variant="caption" fontWeight="bold">
              Nombre*
            </Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Nombre"
              key="name"
              id="name"
              type="name"
              name="name"
              value={controls.name}
              onChange={handleChange}
              style={{ paddingBottom: '8px', marginBottom: '5%' }}
              fullWidth
              error={!!errors?.name[0]}
              helperText={errors?.name[0]}
            />
          </Grid>

          <Grid item md={12} pb={2}>
            <Typography variant="caption" fontWeight="bold">
              Email*
            </Typography>
          </Grid>
          <Grid item md={12}>
            <TextField
              label="Email"
              key="email"
              id="email"
              type="email"
              name="email"
              value={controls.email}
              onChange={handleChange}
              style={{ paddingBottom: '8px', marginBottom: '5%' }}
              fullWidth
              error={!!errors?.email[0]}
              helperText={errors?.email[0]}
            />
          </Grid>
          <Grid item md={12} pb={2}>
            <Typography variant="caption" fontWeight="bold">
              Rol*
            </Typography>
          </Grid>
          <Grid item md={12}>
            <Select
              id="rol"
              type="rol"
              name="rol"
              defaultValue={controls.rol}
              onChange={handleChange}
              sx={{ width: '100%' }}>
              <MenuItem value="user">Usuario</MenuItem>
              <MenuItem value="admin">Administrador</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container item md={8} pt={6} pb={6} spacing={2}>
          {handleIsNew().buttons}
        </Grid>
      </Grid>
    </form>
  );
}

export default UserForm;
