import { Modal } from '@mui/material';
import { ConfirmDialog } from 'content/shared';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { deleteItemBalance } from '../details-payroll.actions';
import { employeeDataSelector } from '../details-payroll.selector';

function ItemDeleteModal({ isOpen, setIsOpen, idDelete }) {
  const dispatch = useDispatch();
  const data = useSelector(employeeDataSelector);

  const handleCloseConfirm = () => {
    dispatch(
      deleteItemBalance({
        idPayroll: data.payroll.id,
        idItemBalance: data.itemBalance?.find((item) => item.itemType.id === idDelete)?.id,
      }),
    );
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ConfirmDialog
        open={isOpen}
        message="¿Estás seguro que quieres eliminar?"
        severity="warning"
        onCloseConfirm={handleCloseConfirm}
        onClose={handleClose}
      />
    </Modal>
  );
}

export default ItemDeleteModal;
