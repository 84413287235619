const BASE = '[ListPayroll]';

const ListPayrollTypes = {
  FETCH_PAYROLL: `${BASE} FETCH_PAYROLL`,
  FETCH_PAYROLL_SUCCESS: `${BASE} FETCH_PAYROLL_SUCCESS`,
  FETCH_PAYROLL_FAIL: `${BASE} FETCH_PAYROLL_FAIL`,
  FETCH_PAYROLL_CARDS: `${BASE} FETCH_PAYROLL_CARDS`,
  FETCH_PAYROLL_CARDS_SUCCESS: `${BASE} FETCH_PAYROLL_CARDS_SUCCESS`,
  FETCH_PAYROLL_CARDS_FAIL: `${BASE} FETCH_PAYROLL_CARDS_FAIL`,
  CHANGE_PAGINATION: `${BASE} CHANGE_PAGINATION`,
  CHANGE_SEARCH_VALUE: `${BASE} CHANGE_SEARCH_VALUE`,
  RESET_STATE: `${BASE} RESET_STATE`,
  CHANGE_DATE_VALUE: `${BASE} CHANGE_DATE_VALUE`,
  CHANGE_PROCESS_VALUE: `${BASE} CHANGE_PROCESS_VALUE`,
  CHANGE_STATUS_VALUE: `${BASE} CHANGE_STATUS_VALUE`,
  CHANGE_LOCATION_KEY: `${BASE} CHANGE_LOCATION_KEY`,
};

export default ListPayrollTypes;
