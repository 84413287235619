import React from 'react';

/* Material UI */
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomDivider from './CustomDivider';

function PageTitle(props) {
  const { title, displayDivider, displayBackButton, variant } = props;

  const titleVariant = variant || 'h3';

  const handleGoBack = () => {
    window.history.back();
  };

  const renderBackButton = () => {
    if (displayBackButton) {
      return (
        <IconButton onClick={handleGoBack}>
          <ArrowBackIcon color="primary" fontSize="large" />
        </IconButton>
      );
    }
    return null;
  };

  const renderDivider = () => {
    if (displayDivider) {
      return <CustomDivider color="secondary" type="title" />;
    }
    return null;
  };

  return (
    <Box display="flex" alignItems="flex-start">
      {renderBackButton()}
      <Box paddingBottom={2} md={12}>
        <Typography variant={titleVariant} fontWeight="bold">
          {title}
        </Typography>
        {renderDivider()}
      </Box>
    </Box>
  );
}

PageTitle.defaultProps = {
  displayDivider: false,
  displayBackButton: false,
};

export default PageTitle;
