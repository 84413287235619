import React from 'react';

import { useTheme } from '@mui/material/styles';
import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Typography,
} from '@mui/material';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CustomDivider from './CustomDivider';

/* eslint-disable */
function CustomTable({
  color,
  data,
  buttonOptions,
  isPaginated = false,
  headersFontWeight = 'bold',
  title,
  paginationOptions,
  onChange,
  total = false,
  showTotal = false,
  handleClick,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const redirectToDetails = (id) => navigate(`${buttonOptions?.url}/${id}`);
  const handleChange = (event, value) => {
    onChange(value);
  };
  const user = useSelector((state) => state.app.user);
  return (
    <>
      {title && (
        <>
          <Typography fontWeight="bold" fontSize={20} isshown={title}>
            {title}
          </Typography>
          <CustomDivider color="primary" type="card" />
        </>
      )}

      <TableContainer
        sx={{ paddingTop: '24px', paddingBottom: total ? '0px' : '48px', overflowX: 'hidden' }}>
        {data.items?.length > 0 ? (
          <Table size="small" aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  borderBottom: 2,
                  borderBottomColor: theme.palette[color].main,
                }}>
                {data.headers.map((header) => (
                  <TableCell key={header.itemKey} sx={{ textAlign: header.align || 'left' }}>
                    <Typography fontWeight={headersFontWeight} fontSize={14}>
                      {header.name}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.items.map((item) => (
                <TableRow
                  key={item.id}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                      borderBottom: total ? 1 : 0,
                      borderBottomColor: total ? theme.palette.primary.main : 0,
                    },
                  }}>
                  {data.headers.map((header) => (
                    <TableCell key={header.itemKey + item.id} align={header.align || 'left'}>
                      {header.itemKey === 'deleteButton' && user.type === 'admin'
                        ? parseInt(item.acumulated.replace(/[$.]/g, '')) > 0 && (
                            <IconButton
                              onClick={() => handleClick(item.id)}
                              sx={{ paddingTop: 0, paddingBottom: 0 }}>
                              <DeleteIcon style={{ color: 'red' }} />
                            </IconButton>
                          )
                        : item[header.itemKey]}
                    </TableCell>
                  ))}
                  {buttonOptions.isshown && (
                    <TableCell sx={{ textAlign: 'right' }}>
                      <IconButton type="button" onClick={() => redirectToDetails(item.id)}>
                        <ArrowCircleRightIcon color="primary" fontSize="large" />
                      </IconButton>
                    </TableCell>
                  )}
                </TableRow>
              ))}
              {showTotal && (
                <TableRow
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                      borderTop: 2,
                      borderTopColor: theme.palette.primary.main,
                    },
                  }}>
                  <TableCell>
                    <Typography fontWeight="bold" fontSize={14} sx={{ textAlign: 'right' }}>
                      Total
                    </Typography>
                  </TableCell>
                  {data.total.map((total, idx) => (
                    <TableCell key={idx}>
                      <Typography fontWeight="bold" fontSize={14} sx={{ textAlign: 'right' }}>
                        {total}
                      </Typography>
                    </TableCell>
                  ))}
                </TableRow>
              )}
            </TableBody>
          </Table>
        ) : (
          <Typography component="div" align="center" sx={{ marginBottom: '7px', marginTop: '7px' }}>
            No hay datos
          </Typography>
        )}
      </TableContainer>
      {isPaginated && (
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <Pagination
            count={paginationOptions?.totalPage}
            page={paginationOptions?.actualPage}
            onChange={handleChange}
          />
        </Stack>
      )}
    </>
  );
}

CustomTable.defaultProps = {
  buttonOptions: {
    url: '/',
    isshown: false,
  },
};

export default CustomTable;
