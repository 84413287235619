import { LoadingButton } from '@mui/lab';
import { Card, CardContent, Grid, TextField } from '@mui/material';
import PageTitle from 'content/features/other/components/PageTitle';
import useForm from 'hooks/useForm';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validatePassword } from 'utils/functions';
import { useNavigate } from 'react-router-dom';
import { updatePassword } from '../changePassword.actions';

function ChangePasswordForm(props) {
  const { controls, onChangeText, loading } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.app.user);

  const { onChange, onSubmit, errors } = useForm(controls, null, {
    onChange: onChangeText,
    validations: {
      password: [
        {
          check: (value) => validatePassword(value).check,
          message: validatePassword('').message,
        },
      ],
      rePassword: [
        {
          check: (value) => !!value,
          message: 'La verificación de contraseña es requerida',
        },
        {
          check: (value) => value === controls.password,
          message: 'Las contraseñas deben coincidir',
        },
      ],
    },
  });

  const handleChange = (event) => {
    event.preventDefault();
    onChange(event);
  };
  const onClickUpdatePassword = (event) => {
    event.preventDefault();
    onSubmit(() => dispatch(updatePassword({ password: controls.password, user, navigate })));
  };
  return (
    <form onSubmit={onClickUpdatePassword}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ margin: 0, height: '88vh' }}>
        <Grid item md={6} lg={6} xs={12}>
          <Card elevation={2} style={{ margin: '0px 10px', boxShadow: 'none' }}>
            <CardContent>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={10}>
                  <PageTitle title="Actualizar contraseña" displayDivider="true" variant="h4" />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    autoFocus
                    label="Nueva contraseña"
                    key="password"
                    id="password"
                    type="password"
                    name="password"
                    value={controls.password}
                    onChange={handleChange}
                    style={{ paddingBottom: '8px' }}
                    fullWidth
                    error={!!errors.password[0]}
                    helperText={errors.password[0]}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <TextField
                    label="Repetir contraseña"
                    id="rePassword"
                    type="password"
                    key="rePassword"
                    name="rePassword"
                    onChange={handleChange}
                    value={controls.rePassword}
                    fullWidth
                    error={!!errors.rePassword[0]}
                    helperText={errors.rePassword[0]}
                  />
                </Grid>
                <Grid item xs={10} textAlign="center">
                  <LoadingButton
                    data-testid="button-login"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    sx={{ marginTop: '5px', width: '100%' }}
                    loadingPosition="start"
                    loading={loading}>
                    Guardar
                  </LoadingButton>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </form>
  );
}

export default ChangePasswordForm;
