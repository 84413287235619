import { createReducer } from '@reduxjs/toolkit';
import {
  fetchPayroll,
  fetchPayrollSuccess,
  fetchPayrollFail,
  changePagination,
  changeSearchValue,
  changeDateValue,
  setResetState,
  changeProcessValue,
  changeStatusValue,
  fetchPayrollCards,
  fetchPayrollCardsSuccess,
  fetchPayrollCardsFail,
  changeLocationKey,
} from './list-payroll.actions';

const initialState = {
  currentPage: 1,
  pageSize: 10,
  totalPages: 1,
  query: {},
  data: [],
  isLoading: false,
  error: null,
  dataCards: [],
  isLoadingCards: false,
  errorCards: null,
  searchValue: '',
  dateValue: '01-09-2023', // TODO: PRIMER DIA DEL MES ACTUAL
  processValue: '0',
  statusValue: '1',
  locationKey: '',
};
const ListPayrollReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(fetchPayroll, (state) => {
      state.isLoading = true;
      state.error = null;
    })
    .addCase(fetchPayrollSuccess, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.objects;
      state.totalPages = action.payload.totalPages;
    })
    .addCase(fetchPayrollFail, (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    })
    .addCase(fetchPayrollCards, (state) => {
      state.isLoadingCards = true;
      state.errorCards = null;
    })
    .addCase(fetchPayrollCardsSuccess, (state, action) => {
      state.isLoadingCards = false;
      state.dataCards = action.payload.objects;
    })
    .addCase(fetchPayrollCardsFail, (state, action) => {
      state.isLoadingCards = false;
      state.errorCards = action.payload.error;
    })
    .addCase(changePagination, (state, action) => {
      state.currentPage = action.payload.currentPage;
    })
    .addCase(changeSearchValue, (state, action) => {
      state.searchValue = action.payload.searchValue;
      state.currentPage = 1;
    })
    .addCase(changeDateValue, (state, action) => {
      state.dateValue = action.payload.dateValue;
      state.currentPage = 1;
    })
    .addCase(changeProcessValue, (state, action) => {
      state.processValue = action.payload.processValue;
      state.currentPage = 1;
    })
    .addCase(changeStatusValue, (state, action) => {
      state.statusValue = action.payload.statusValue;
      state.currentPage = 1;
    })
    .addCase(setResetState, (state) => {
      state.searchValue = '';
      state.currentPage = 1;
      state.dateValue = '01-09-2023'; // TODO: PRIMER DIA DEL MES ACTUAL
      state.processValue = '0';
      state.statusValue = '1';
    })
    .addCase(changeLocationKey, (state, action) => {
      state.locationKey = action.payload.locationKey;
    });
});

export default ListPayrollReducer;
