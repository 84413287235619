import { useItemType } from 'content/features/item-type';
import ItemTypeFeed from 'content/features/item-type/components/ItemTypeFeed';
import React from 'react';

function ItemType() {
  useItemType();
  return <ItemTypeFeed />;
}

export default ItemType;
