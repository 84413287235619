export const loadingLoadSelector = (state) => state.bulkLoad.loaders.load;
export const loadingProcessSelector = (state) => state.bulkLoad.extraction.progress;
export const insertedRecordsSelector = (state) => state.bulkLoad.extraction.record;
export const monthSelector = (state) => state.bulkLoad.extraction.month;
export const yearSelector = (state) => state.bulkLoad.extraction.year;
export const dateSelector = (state) => state.bulkLoad.extraction.date;
export const finishSelector = (state) => state.bulkLoad.extraction.finish;
export const loadIsDisabledSelector = (state) => state.bulkLoad.extraction.isDisabled;

export const loadingSendSelector = (state) => state.bulkLoad.loaders.send;
export const sendingProcessSelector = (state) => state.bulkLoad.send.progress;
export const updatedRecordsSelector = (state) => state.bulkLoad.send.record;
export const lastMonthSendSelector = (state) => state.bulkLoad.send.month;
export const lastYearSendSelector = (state) => state.bulkLoad.send.year;
export const lastDateSendSelector = (state) => state.bulkLoad.send.date;
export const finishSendSelector = (state) => state.bulkLoad.send.finish;
export const sendIsDisabledSelector = (state) => state.bulkLoad.send.isDisabled;

const BulkLoadSelector = {
  loadingLoad: loadingLoadSelector,
  loadingProcess: loadingProcessSelector,
  insertedRecords: insertedRecordsSelector,
  month: monthSelector,
  year: yearSelector,
  date: dateSelector,
  finish: finishSelector,
  loadIsDisabled: loadIsDisabledSelector,

  loadingSend: loadingSendSelector,
  sendingProcess: sendingProcessSelector,
  updatedRecords: updatedRecordsSelector,
  lastMonthSend: lastMonthSendSelector,
  lastYearSend: lastYearSendSelector,
  lastDateSend: lastDateSendSelector,
  finishSend: finishSendSelector,
  sendIsDisabled: sendIsDisabledSelector,
};

export default BulkLoadSelector;
