import { Modal } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { resetControls } from '../itemType.actions';
import ItemTypeForm from './ItemTypeForm';

function ItemTypeModal({ modal, setModal }) {
  const dispatch = useDispatch();
  const handleClose = () => {
    setModal({
      isOpen: false,
      isNew: false,
    });
    dispatch(resetControls());
  };
  return (
    <div>
      <Modal open={modal.isOpen} onClose={handleClose}>
        <ItemTypeForm modal={modal} setModal={setModal} />
      </Modal>
    </div>
  );
}

export default ItemTypeModal;
