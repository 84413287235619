import React from 'react';

import { Card, CardContent, Typography } from '@mui/material';
import CustomDivider from './CustomDivider';

function CustomCard({ title, content, color }) {
  return (
    <Card sx={{ width: 'fit-content' }}>
      <CardContent sx={{ p: '5px 18px 5px 18px !important', textAlign: 'center' }}>
        <Typography fontWeight="bold" fontSize={16}>
          {content}
        </Typography>
        <CustomDivider color={color} type="card" />
        <Typography sx={{ fontSize: 12 }} fontWeight="bold">
          {title}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default CustomCard;
