/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/es';

/* Redux */
import { useDispatch, useSelector } from 'react-redux';

/* Material UI */
import { Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material';

/* Project */
import {
  clearSelectedEmployee,
  fetchEmployee,
  sendEmployeePayroll,
  updateEmployee,
} from 'content/features/details-payroll/details-payroll.actions';
import {
  loadingSelector,
  employeeDataSelector,
  updateLoadingSelector,
  sendLoadingSelector,
  successfulDeleteSelector,
} from 'content/features/details-payroll/details-payroll.selector';
import CustomCard from 'content/features/other/components/CustomCard';
import CustomDivider from 'content/features/other/components/CustomDivider';
import CustomTable from 'content/features/other/components/CustomTable';
import PageTitle from 'content/features/other/components/PageTitle';
import {
  amountsByKeys,
  formatNumber,
  formatRut,
  getProcess,
  isDiscounteble,
  mergedKeys,
  numberToMonth,
} from 'utils/functions';
import CustomTag from 'content/features/other/components/CustomTag';
import { LoadingButton } from '@mui/lab';
import ItemDeleteModal from 'content/features/details-payroll/components/ItemDeleteModal';

function DetailsPayroll() {
  moment.locale('es');
  const dispatch = useDispatch();
  const { id } = useParams();
  const data = useSelector(employeeDataSelector);
  const loading = useSelector(loadingSelector);
  const updateLoading = useSelector(updateLoadingSelector);
  const sendLoading = useSelector(sendLoadingSelector);
  const successfulDelete = useSelector(successfulDeleteSelector);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [idDelete, setIdDelete] = useState(0);
  const user = useSelector((state) => state.app.user);

  useEffect(() => {
    dispatch(fetchEmployee({ idPayroll: id }));
    return () => {
      dispatch(clearSelectedEmployee());
    };
  }, [updateLoading, sendLoading, successfulDelete]);
  const discount = data.payroll.items
    ?.filter((item) => item.itemType.bukType === 'descuento')
    .reduce((acc, curr) => acc + parseInt(curr.bukAmount, 10), 0);

  const notDiscount = data.payroll.itemsBalances?.reduce(
    (acc, curr) => acc + parseInt(curr.amount, 10),
    0,
  );

  const asignedDiscounts = data.payroll.bukTotalOtherDiscount;
  const acumulatedAmount = data.itemBalance?.reduce((acc, curr) => acc + Number(curr.amount), 0);
  const assets = {
    headers: [
      { name: 'Descripción', itemKey: 'description' },
      { name: 'Descontable', itemKey: 'discountable' },
      { name: 'Importe', itemKey: 'amount', align: 'right' },
    ],
    items: data.payroll.items
      ?.filter((item) => item.itemType.bukType === 'haber')
      .map((item) => ({
        id: item.id,
        amount: `$ ${formatNumber(item.bukAmount)}`,
        description: item.itemType.name,
        discountable: isDiscounteble(item.itemType.discountType.name),
      })),
  };

  const discountsItems = data.payroll.items?.filter(
    (item) => item.itemType.bukType === 'descuento' || item.itemType.bukType === 'credit',
  );
  const acumulatedItems = data.payroll.itemsBalances;
  const mergedKeysObj = mergedKeys(data.itemBalance, discountsItems?.concat(acumulatedItems));
  const mergedDiscounts = amountsByKeys(data, mergedKeysObj);

  const handleClick = (idItem) => {
    setIsOpenDeleteModal(true);
    setIdDelete(idItem);
  };

  const discounts = {
    headers: [
      { name: 'Descripción', itemKey: 'description' },
      { name: 'Acumulado anterior', itemKey: 'acumulated', align: 'right' },
      data.payroll.isOpen && { name: '', itemKey: 'deleteButton' },
      { name: 'Asignado del mes', itemKey: 'asignated', align: 'right' },
      { name: 'Total', itemKey: 'total', align: 'right' },
    ],
    items: mergedDiscounts.map((item) => ({
      id: item.id,
      description: item.name,
      acumulated: `$ ${formatNumber(item.previousAcumulated)}`,
      asignated: `$ ${formatNumber(item.assigned)}`,
      total: `$ ${formatNumber(Number(item.previousAcumulated) + Number(item.assigned))}`,
    })),
    total: [
      `$ ${formatNumber(mergedDiscounts.reduce((acc, curr) => acc + curr.previousAcumulated, 0))}`,
      '',
      `$ ${formatNumber(mergedDiscounts.reduce((acc, curr) => acc + curr.assigned, 0))}`,
      `$ ${formatNumber(
        mergedDiscounts.reduce(
          (acc, curr) => acc + Number(curr.previousAcumulated) + Number(curr.assigned),
          0,
        ),
      )}`,
    ],
  };

  const regularizedDiscounts = {
    headers: [
      { name: 'Descripción', itemKey: 'description' },
      { name: 'Descontado', itemKey: 'discounted', align: 'right' },
      { name: 'No descontado', itemKey: 'notDiscounted', align: 'right' },
      { name: 'Total', itemKey: 'total', align: 'right' },
    ],
    items: mergedDiscounts.map((item) => ({
      id: item.id,
      description: item.name,
      discounted: `$ ${formatNumber(item.discounted)}`,
      notDiscounted: `$ ${formatNumber(item.acumulated)}`,
      total: `$ ${formatNumber(Number(item.discounted) + Number(item.acumulated))}`,
    })),
    total: [
      `$ ${formatNumber(mergedDiscounts.reduce((acc, curr) => acc + curr.discounted, 0))}`,
      `$ ${formatNumber(mergedDiscounts.reduce((acc, curr) => acc + curr.acumulated, 0))}`,
      `$ ${formatNumber(
        mergedDiscounts.reduce(
          (acc, curr) => acc + Number(curr.discounted) + Number(curr.acumulated),
          0,
        ),
      )}`,
    ],
  };

  const cardContent = [
    {
      title: 'Acumulado anterior',
      content: `$ ${formatNumber(acumulatedAmount)}`,
      color: 'primary',
    },
    {
      title: 'Asignado del mes',
      content: `$ ${formatNumber(asignedDiscounts)}`,
      color: 'primary',
    },
    {
      title: 'Descontado',
      content: `$ ${formatNumber(discount)}`,
      color: 'primary',
    },
    {
      title: 'No descontado',
      content: `$ ${formatNumber(notDiscount)}`,
      color: 'primary',
    },
  ];
  const totalAssets = `$ ${formatNumber(
    data.payroll.items
      ?.filter((item) => item.itemType.bukType === 'haber')
      ?.reduce((acc, curr) => acc + Number(curr.bukAmount), 0),
  )}`;

  const totalDiscountableAssets = data.payroll.items
    ?.filter((item) => item.itemType.bukType === 'haber')
    ?.filter((item) => item.itemType.discountType.name === 'Haber descontable')
    ?.reduce((acc, curr) => acc + Number(curr.bukAmount), 0);

  const totalDiscountable = `$ ${formatNumber(
    totalDiscountableAssets - data.payroll.bukTotalLegalDiscounts,
  )}`;

  const handleUpdate = (event) => {
    event.preventDefault();
    dispatch(updateEmployee({ idPayroll: id }));
  };

  const handleSend = (event) => {
    event.preventDefault();
    dispatch(sendEmployeePayroll({ idPayroll: id }));
  };

  const Modal = (
    <ItemDeleteModal
      isOpen={isOpenDeleteModal}
      setIsOpen={setIsOpenDeleteModal}
      idDelete={idDelete}
    />
  );

  return (
    <>
      {Modal}
      <Grid container alignItems="center" spacing={2}>
        <Grid item>
          <PageTitle title="Detalles de liquidación" displayBackButton="true" />
        </Grid>
        <Grid item>
          <CustomTag isOpen={data.payroll.isOpen} />
        </Grid>
      </Grid>
      {loading ? (
        <CircularProgress disableShrink />
      ) : (
        <Grid
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          paddingTop={3}>
          {user.type === 'admin' && (
            <Grid item container md={12} spacing={2} pb={2}>
              <Grid item>
                <LoadingButton
                  onClick={handleUpdate}
                  loading={updateLoading}
                  disabled={!data.payroll.isOpen}
                  variant="contained"
                  size="large"
                  color="secondary"
                  sx={{ height: '60px' }}>
                  Extraer Liquidación
                </LoadingButton>
              </Grid>
              <Grid item>
                <LoadingButton
                  onClick={handleSend}
                  loading={sendLoading}
                  disabled={!data.payroll.isOpen}
                  variant="contained"
                  size="large"
                  color="secondary"
                  sx={{ height: '60px' }}>
                  Enviar insoluto
                </LoadingButton>
              </Grid>
            </Grid>
          )}

          {cardContent.map((card) => (
            <Grid item key={card.title}>
              <CustomCard title={card.title} content={card.content} color={card.color} />
            </Grid>
          ))}
          <Grid item xs={12} paddingTop={4} paddingBottom={4}>
            <Card>
              <CardContent padding="50px">
                <Typography fontWeight="bold" fontSize={20}>
                  Información
                </Typography>
                <CustomDivider color="primary" type="card" />
                <Grid container paddingTop={2} justifyContent="start" rowSpacing={2}>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Nombre
                    </Typography>
                    <Typography fontSize={14}>{data.employee.bukName}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      RUT
                    </Typography>
                    <Typography fontSize={14}>{formatRut(data.employee.bukRut)}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Días trabajados
                    </Typography>
                    <Typography fontSize={14}>{data.payroll.bukWorkedDays}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Días inasistencia
                    </Typography>
                    <Typography fontSize={14}>{data.payroll.bukNoworkedDays}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Periodo
                    </Typography>
                    <Typography fontSize={14}>{`${numberToMonth(data.payroll.bukMonth - 1)} ${
                      data.payroll.bukYear
                    }`}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Proceso
                    </Typography>
                    <Typography fontSize={14}>{getProcess(data.payroll.isReissued)}</Typography>
                  </Grid>
                  <Grid item md={3}>
                    <Typography fontWeight="bold" fontSize={14}>
                      Fecha de actualización
                    </Typography>
                    <Typography fontSize={14}>
                      {moment(data.payroll.updatedAt).format('DD-MM-YYYY HH:mm:ss')}
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <CustomTable
                color="yellow"
                data={assets}
                headersFontWeight="bold"
                title="Haberes"
                total
              />
            </Grid>
            <Grid item container md={12} justifyContent="flex-end" p={2}>
              <Grid item container justifyContent="space-between" md={3}>
                <Grid item>
                  <Typography fontSize={14} fontWeight="bold">
                    Total
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={14}>{totalAssets}</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={12} justifyContent="flex-end" p={2}>
              <Grid item container justifyContent="space-between" md={3}>
                <Grid item>
                  <Typography fontSize={14} fontWeight="bold">
                    Total descuentos legales
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={14}>
                    $ {formatNumber(data.payroll.bukTotalLegalDiscounts)}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container md={12} justifyContent="flex-end" p={2}>
              <Grid item container justifyContent="space-between" md={3}>
                <Grid item>
                  <Typography fontSize={14} fontWeight="bold">
                    Total descontable
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography fontSize={14} fontWeight="bold">
                    {totalDiscountable}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={12}>
              <CustomTable
                color="yellow"
                data={discounts}
                headersFontWeight="bold"
                title="Descuentos"
                total
                showTotal
                handleClick={handleClick}
              />
            </Grid>

            <Grid item md={12}>
              <CustomTable
                color="yellow"
                data={regularizedDiscounts}
                headersFontWeight="bold"
                title="Descuentos regularizados"
                total
                showTotal
              />
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default DetailsPayroll;
